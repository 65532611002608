import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { setCookieWithExpiration } from "@multimediallc/web-utils/storage"
import { Component } from "../../../../common/defui/component"
import { ignoreMetaClick } from "../../../../common/DOMutils"
import { followingEvent } from "../../../../common/follow"
import { siteHeaderMenuOpened, toggleDms } from "../../../../common/mobilelib/userActionEvents"
import { addPageAction } from "../../../../common/newrelic"
import { pageContext } from "../../../interfaces/context"
import { bindMobileDmsMenu, bindUserUpdatesPanel } from "./userMenuDropDown"

interface MobilePushMenuProps {
    menuButton: HTMLDivElement
}

const TRANSITION_MS = 150  // Match transition in `mobile_menu.scss`
const ANIMATE_CLASS = "pushmenu-animate"
const NO_SCROLL_CLASS = "no-scroll"
const MENU_EXPANDED_CONTENT_CLASS = "push-page-content"

export class MobilePushMenu extends Component<HTMLElement, MobilePushMenuProps> {
    private menuButton: HTMLDivElement
    private menuContent: Component
    private pushOverlay: Component
    private desktopLink: HTMLElement
    private logoutLink: HTMLElement | null
    private socialIconsContainer: HTMLElement | null
    private followingLink: HTMLElement | null
    private lastScrollTop = 0

    protected initUI(props: MobilePushMenuProps): void {
        this.menuButton = props.menuButton
        this.menuContent = new Component(this.element.querySelector<HTMLElement>(".main-menu")!)
        this.pushOverlay = new Component(this.element.querySelector<HTMLElement>(".push-overlay")!)
        this.desktopLink = this.element.querySelector("#mobile_desktop_link")!
        this.logoutLink = this.element.querySelector("#logout-conf")
        this.socialIconsContainer = this.element.querySelector("#mobile-social-media-icons")
        this.followingLink = this.element.querySelector("#follow_page_link")

        this.menuButton.addEventListener("click", this.onMenuOpen.bind(this))
        this.pushOverlay.element.addEventListener("click", this.onMenuClosed.bind(this))
        this.bindSubMenus()
        this.bindHeaderOpenedEvents()
        this.bindLogoutLink()
        this.bindDesktopLink()
        this.bindSocialMediaIcons()
        this.bindFollowCountUpdates()

        this.bindRoomlistLinks()
    }

    private onMenuOpen(): void {
        this.showElement()
        this.pushOverlay.showElement()
        document.querySelectorAll(".content, header").forEach((el) => {
            el.classList.add(MENU_EXPANDED_CONTENT_CLASS)
        })
        this.setScroll(true)

        // wait for pushmenu display before adding transition
        window.setTimeout(() => {
            this.menuContent.element.classList.add(ANIMATE_CLASS)
        })

        addPageAction("MobileMenuClick", {
            "attributes.is_orange": this.menuButton.classList.contains("has_unseen"),
        })
        siteHeaderMenuOpened.fire(true)
    }

    private onMenuClosed(): void {
        this.menuContent.element.classList.remove(ANIMATE_CLASS)
        this.pushOverlay.element.style.opacity = "0"  // Opacity transition handled in scss
        document.querySelectorAll(".content, header").forEach((el) => {
            el.classList.remove(MENU_EXPANDED_CONTENT_CLASS)
        })
        this.setScroll(false)
        window.setTimeout(() => {
            this.hideElement()
            this.pushOverlay.element.style.opacity = ""  // Transition finished
        }, TRANSITION_MS)
        siteHeaderMenuOpened.fire(false)
    }

    private setScroll(isMenuOpen: boolean): void {
        if (isMenuOpen) {
            this.lastScrollTop = window.scrollY
            document.body.classList.add(NO_SCROLL_CLASS)
            document.body.style.top = `-${this.lastScrollTop}px`
        } else {
            document.body.classList.remove(NO_SCROLL_CLASS)
            document.body.style.top = ""
            window.scrollTo(0, this.lastScrollTop)
        }
    }

    private bindSubMenus(): void {
        const updatesBellRoot = this.element.querySelector<HTMLDivElement>("#userUpdatesBellRoot")
        bindUserUpdatesPanel(
            updatesBellRoot,
            this.element.querySelector<HTMLDivElement>("#userUpdatesMenuDropdownRoot"),
            true,  // mobile
            this.menuButton,
        )
        if (!pageContext.current.isAgeGated) {
            bindMobileDmsMenu(
                this.element.querySelector<HTMLDivElement>("#dmListIconRoot"),
                updatesBellRoot,
                this.menuButton,
                this.element.querySelector<HTMLDivElement>("#dmListDropdownRoot"),
            )
        }
    }

    private isPushMenuOpen(): boolean {
        return this.element.style.display === "block"
    }

    private bindHeaderOpenedEvents(): void {
        toggleDms.listen((shouldOpen: boolean) => {
            if (shouldOpen && !this.isPushMenuOpen()) {
                this.onMenuOpen()
            } else if (!shouldOpen && this.isPushMenuOpen()) {
                this.onMenuClosed()
            }
        })
    }

    private bindLogoutLink(): void {
        if (this.logoutLink !== null) {  // Not rendered for anon users
            const logoutForm = this.element.querySelector<HTMLFormElement>("#user_logout_form")!
            this.logoutLink.addEventListener("click", (e) => {
                e.preventDefault()
                if (window.confirm("Are you sure you want to log out?")) {  // eslint-disable-line no-alert
                    logoutForm.submit()
                }
            })
        }
    }

    private bindDesktopLink(): void {
        this.desktopLink.addEventListener("click", () => {
            setCookieWithExpiration("mobile_redirect", "never", { hours: 18 }, "/", true)
            setCookieWithExpiration("mobile_redirect_source", "user", { hours: 18 }, "/", true)
            addPageAction("Mobile_DesktopLinkClicked")
        })
    }

    private bindSocialMediaIcons(): void {
        if (this.socialIconsContainer !== null) {  // Not rendered on whitelabels
            this.socialIconsContainer.querySelectorAll<HTMLAnchorElement>("a").forEach((el) => {
                el.addEventListener("click", () => {
                    addPageAction("ClickSocialMediaLink", {
                        "attributes.source": "mobile",
                        "attributes.clicked_socialmedia": el.dataset["social-type"],
                    })
                })
            })
        }
    }

    // Replaces the inline JS follow count logic in roomlist.html
    private bindFollowCountUpdates(): void {
        if (this.followingLink === null) {
            return  // Not rendered for logged-out users
        }
        const totalEl = this.followingLink.querySelector("#follow_count_total")!
        const onlineEl = this.followingLink.querySelector("#follow_count_online")!
        followingEvent.listen((event) => {
            const totalCount = event.totalCount ?? 0
            const onlineCount = event.onlineCount ?? 0
            if (totalCount > 0) {
                this.followingLink!.style.display = "block"
                onlineEl.textContent = `${onlineCount}`
                totalEl.textContent = `${totalCount}`
            } else {
                this.followingLink!.style.display = "none"
            }
        })
    }

    private bindRoomlistLinks(): void {
        this.element.querySelectorAll(".pushmenu-link.ts-roomlist-link a").forEach((el) => {
            el.addEventListener("click", (event) => {
                ignoreMetaClick(event as MouseEvent, () => {
                    event.preventDefault()
                    this.onMenuClosed()
                    UrlState.current.navigateTo((event.target as HTMLAnchorElement).href)
                })
            })
        })
    }
}
