import { hasWellSupportedEmojis } from "@multimediallc/web-utils/modernizr"
import twemoji from "@twemoji/api"
import { addColorClass } from "../../cb/colorClasses"
import { MentionUserList } from "../../cb/components/userMentions/MentionUserList"
import { pageContext } from "../../cb/interfaces/context"
import { normalizeResource } from "../api"
import { roomLoaded } from "../context"
import { setPureChatColorData } from "../fullvideolib/pureChatUtil"
import { PartType } from "../messageInterfaces"
import { brighten, createBaseMessageDiv, createMessageContentDiv, setTimestamp } from "../messageToDOM"
import { addPageAction } from "../newrelic"
import { enterSpyShowAlertChain } from "../privateShow"
import { renderMessage, renderShortcodeMessage, renderShortcodePart } from "../renderMessage"
import { RoomStatus } from "../roomStatus"
import { getBrightUsernameColor } from "../roomUtil"
import { ShortcodeParser } from "../specialoutgoingmessages"
import { i18n } from "../translation"
import { TabName } from "./tabList"
import { openTipCalloutRequest, userSwitchedTab } from "./userActionEvents"
import { createUsernameLabel } from "./userToDOM"
import type { IShortcode } from "../../cb/interfaces/shortcode"
import type { IChatConnection } from "../context"
import type { IRoomMessage, IRoomNoticePart, IShortcodeMessage, IUserInfo } from "../messageInterfaces"

export const mobileMessagePaddingPx = 2

let gender: string
let chatConnection: IChatConnection

// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    gender = context.dossier.roomGender
    chatConnection = context.chatConnection
})

export function createRoomMessage(m: IRoomMessage): HTMLDivElement {
    const div = createBaseMessageDiv(m.fromUser.username)

    const content = createMessageContentDiv()
    content.style.padding = `0px ${mobileMessagePaddingPx}px`
    if (m.backgroundColor !== undefined) {
        content.style.background = m.backgroundColor
    }
    div.appendChild(content)

    const username = createUsernameLabel(m.fromUser, m)
    username.dataset.testid = "chat-message-username"
    username.style.paddingRight = "4px"
    setPureChatColorData(username, getBrightUsernameColor(m.fromUser))
    content.appendChild(username)

    const span = renderMessage(m.message)
    span.dataset.testid = "chat-message-text"
    const textColor = (m.textColor !== undefined) ? m.textColor : "#000000"
    span.style.color = textColor
    if (m.font !== undefined) {
        username.style.fontFamily = m.font
        span.style.fontFamily = m.font
    }
    setPureChatColorData(span, brighten(textColor))
    styleMobileMention(span)
    content.appendChild(span)

    if (!hasWellSupportedEmojis()) {
        twemoji.parse(div, { className: "emojiChat" })
    }

    setTimestamp(m, div)
    return div
}

export function combineNoticeParts(container: HTMLDivElement, messages: IRoomNoticePart[][], shortcodes?: IShortcode[]): HTMLDivElement {
    for (const message of messages) {
        const msgDiv = document.createElement("div")
        for (const part of message) {
            switch (part.partType) {
                case PartType.message:
                    msgDiv.appendChild(createMessage(part, shortcodes))
                    break
                case PartType.user:
                    msgDiv.appendChild(createUsernameLabel(part.user as IUserInfo))
                    break
                case PartType.hashtag:
                    msgDiv.appendChild(createHashtagAnchor(part.message as string))
                    break
                case PartType.spyPrivateShow:
                    msgDiv.appendChild(createLink(i18n.spyPrivateShowLower, () => {
                        if (chatConnection.status !== RoomStatus.PrivateSpying) {
                            enterSpyShowAlertChain(chatConnection, undefined, {
                                onResolve: () => {
                                    userSwitchedTab.fire(TabName.Private)
                                },
                            })
                        }
                    }))
                    break
                default:
                    error(`Unknown roomNotice type: ${part.partType}`, part)
            }
        }
        container.appendChild(msgDiv)
    }
    return container
}

export function styleMobileMention(element: HTMLElement): void {
    const mentions = element.querySelectorAll<HTMLSpanElement>(".username-mention")
    const invalidMentions = []
    const mentionUserList = MentionUserList.getInstance()
    const userList = mentionUserList.users()
    for (const mentionSpan of mentions) {
        const username = mentionSpan.innerText.replace("@", "").toLowerCase()
        const user = userList.find((u) => u.username === username)
        if (user !== undefined) {
            const userlabel = createUsernameLabel(user, undefined, false, true)
            if (pageContext.current.loggedInUser?.username === user.username) {
                userlabel.style.padding = "2px 4px"
                userlabel.style.borderRadius = "4px"

                addColorClass(userlabel, "mentionHl")
            }
            mentionSpan.parentElement?.replaceChild(userlabel, mentionSpan)
            userlabel.insertAdjacentText("afterend", " ")
        } else {
            invalidMentions.push(username)
        }
    }
    mentionUserList.addInvalidUsers(invalidMentions)
}

function createMessage(part: IRoomNoticePart, shortcodes?: IShortcode[]): HTMLSpanElement {
    if (
        part.message !== undefined &&
        shortcodes &&
        ShortcodeParser.isShortcodeSyntax(part.message)
    ) {
        return renderShortcodePart(
            part,
            shortcodes,
            createShortcodeLink,
            openTipCalloutRequest,
        )
    }
    if (part.parseEmoticon !== false) {
        return renderMessage(part.message as string)
    }
    const span = document.createElement("span")
    span.innerText = part.message as string
    return span
}

export function createShortcodeMessage(message: IShortcodeMessage): HTMLDivElement {
    // Form base message div to to hold our our shortcode msg content
    const div = createBaseMessageDiv(message.fromUser.username)

    const content = createMessageContentDiv()
    content.style.padding = `0px ${mobileMessagePaddingPx}px`
    if (message.backgroundColor !== undefined) {
        content.style.background = message.backgroundColor
    }
    div.appendChild(content)

    const username = createUsernameLabel(message.fromUser, message)
    username.style.paddingRight = "4px"
    setPureChatColorData(username, getBrightUsernameColor(message.fromUser))
    content.appendChild(username)

    const span = renderShortcodeMessage(message, createShortcodeLink, openTipCalloutRequest)
    if (message.font !== undefined) {
        username.style.fontFamily = message.font
        span.style.fontFamily = message.font
    }
    // override the text shadow to make shortcode message more consistent across video modes
    // this can be removed in the future if our mobile fullscreen styling changes
    span.style.textShadow = "rgb(51, 51, 51) 0.2px 0.2px 0px"
    content.appendChild(span)
    styleMobileMention(span)

    setTimestamp(message, div)
    return div
}

function createShortcodeLink(linkText: string, linkUrl: string|undefined, callback: () => void): HTMLAnchorElement {
    const link = createLink(linkText, callback)
    // used by purechat to prevent closing video controls on click
    link.dataset.messagetype = "shortcode"
    if (linkUrl !== undefined && linkUrl !== "") {
        link.href = normalizeResource(linkUrl)
        link.target = "_blank"
    }
    return link
}

function createLink(text: string, onClick: () => void): HTMLAnchorElement {
    const anchor = document.createElement("a")
    anchor.style.color = "#0a5a83"
    anchor.style.textDecoration = "underline"
    anchor.innerText = text
    anchor.onclick = onClick
    return anchor
}

function createHashtagAnchor(hashtag: string): HTMLAnchorElement {
    const anchor = document.createElement("a")
    anchor.href = normalizeResource(`/tag/${hashtag}/${gender ?? ""}`)
    addColorClass(anchor, "msg-link")
    anchor.style.textDecoration = "none"
    anchor.innerText = `#${hashtag}`
    return anchor
}

export function createLogMessage(msg: string, testid=""): HTMLDivElement {
    const d = createBaseMessageDiv()
    if (testid) {
        d.dataset.testid = testid
    }
    d.innerText = msg
    d.style.color = "#000000"
    setPureChatColorData(d, brighten("#aaaaaa"))
    return d
}

export function createSupporterSignupMessage(broadcaster: string, isAgeVerified: boolean): HTMLDivElement {
    function createTextSpan(text: string): HTMLSpanElement {
        const span = document.createElement("span")
        span.innerText = text
        return span
    }

    const div = createBaseMessageDiv()
    setPureChatColorData(div, brighten("#aaaaaa"))

    const pmSupportNotice = isAgeVerified ? i18n.pmSupporterNoticeAgeVerified : i18n.pmSupporterNotice
    div.appendChild(createTextSpan(pmSupportNotice))
    div.appendChild(document.createElement("br"))
    div.appendChild(createTextSpan(`${i18n.pmSupporterSignUp} `))  // Add space at the end to split from the next text.
    const link = createLink(i18n.pmSupporterMembership, () => {})
    link.style.color = ""
    addColorClass(link, "msg-link")
    link.href = normalizeResource(`/supporter/upgrade/?source=${pageContext.current.PurchaseEventSources["SUPPORTER_SOURCE_PM_UPSELL"]}`)
    link.target = "_blank"
    link.onclick = () => {
        addPageAction("SupporterPageOpened", { "source": "chat" })
    }
    div.appendChild(link)
    div.appendChild(createTextSpan(` ${i18n.pmSupporterSendPm(broadcaster)}.`))  // Add space at the beginning.
    return div
}

export function createPMChatLinkMessage(msg: string, pmChatLink: HTMLElement): HTMLDivElement {
    const d = createBaseMessageDiv(msg)
    d.innerText = msg
    d.appendChild(pmChatLink)
    d.style.color = "#000000"
    setPureChatColorData(d, brighten("#aaaaaa"))
    return d
}
