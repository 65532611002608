import { isRoomRoomlistSpaEligiblePage } from "../../cb/components/roomlist/spaHelpers";
import { loginOverlayShown } from "../../cb/ui/loginOverlay"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { getCb, normalizeResource } from "../api"
import { EventRouter } from "../events"
import { featureFlagIsActive } from "../featureFlag";
import { ModalComponent } from "../modalComponent"
import { addPageAction } from "../newrelic"
import { LOGIN_BASE_PATH, SIGN_UP_BASE_PATH } from "../redirectParamUtils"
import { i18n } from "../translation"
import { parseQueryString } from "../urlUtil"
import { mediumPopupLinkFeatures, safeWindowOpen } from "../windowUtils"
import type { IModalOptions } from "../modalComponent"

const iframePath = "/accounts/register_iframe/"
export const showJoinOverlay = new EventRouter<JoinOverlayAnchor>("showJoinOverlay")

type IJoinOverlayProps = Omit<IModalOptions, "easyExit">

export class JoinOverlay extends ModalComponent {
    protected readonly registerLaterLink: HTMLAnchorElement
    protected readonly iframe: HTMLIFrameElement

    constructor(props: IJoinOverlayProps) {
        super({ ...props, easyExit: true, onHide: (fromNavigation: boolean) => {
            props.onHide?.(fromNavigation)
            document.body.style.overflow = ""
            document.body.classList.remove("blurred-login-overlay-shown")
        } })

        this.element.style.display = "none"
        this.element.style.position = "absolute"
        this.element.style.width = "560px"
        this.element.style.height = "auto"
        this.element.style.backgroundColor = "#ffffff"
        this.element.style.border = "2px solid #cccccc"
        this.element.style.borderRadius = "20px"
        this.element.style.padding = "10px"
        this.element.style.top = "45px"
        this.element.style.fontSize = "12px"
        this.element.style.fontFamily = "UbuntuRegular', Arial, Helvetica, sans-serif"

        this.overlay.style.background = ""
        this.overlay.classList.add("blurred-login-overlay")

        this.element.appendChild(this.createLoadingSpinner())

        this.iframe = document.createElement("iframe")
        this.iframe.id = "join_iframe"
        this.iframe.frameBorder = "0"
        this.iframe.marginWidth = "0"
        this.iframe.marginHeight = "0"
        this.iframe.style.width = "560px"
        // leaves enough room for 3 errors to be showing at the same time
        this.iframe.style.height = "650px"
        this.iframe.src = `${iframePath}?next=${encodeURIComponent(window.location.pathname)}`
        if (featureFlagIsActive("AffLinkcodeCookieOnClick")) {
            const hasTourParam = window.location.search.includes("tour=")
            const hasCampaignParam = window.location.search.includes("campaign=")
            if (hasTourParam && hasCampaignParam) {
                // register form reads affiliate params from request so include existing
                this.iframe.src = `${iframePath}${window.location.search}`
                // linkcode can have a next value so don't overwrite if it exists
                if (!window.location.search.includes("next=")) {
                    this.iframe.src += `&next=${encodeURIComponent(window.location.pathname)}`
                }
            }
        }
        this.element.appendChild(this.iframe)

        const hr = document.createElement("hr")
        hr.style.margin = "15px 0 10px 0"
        hr.style.border = "0"
        hr.style.color = "#e4e4e4"
        hr.style.backgroundColor = "#e4e4e4"
        hr.style.height = "1px"
        this.element.appendChild(hr)

        const leftSide = document.createElement("span")

        const arrowImg = document.createElement("img")
        arrowImg.src = `${STATIC_URL}arrow_back.gif`
        arrowImg.style.marginRight = "4px"
        arrowImg.style.verticalAlign = "bottom"
        leftSide.appendChild(arrowImg)

        this.registerLaterLink = document.createElement("a")
        this.registerLaterLink.innerText = i18n.registerLaterText
        this.registerLaterLink.className = "nooverlay"

        leftSide.appendChild(this.registerLaterLink)
        this.element.appendChild(leftSide)

        const rightSide = document.createElement("span")
        rightSide.style.cssFloat = "right"

        const keyImg = document.createElement("img")
        keyImg.src = `${STATIC_URL}key.gif`
        keyImg.style.marginRight = "4px"
        keyImg.style.verticalAlign = "bottom"
        rightSide.appendChild(keyImg)

        const loginLabel = document.createElement("span")
        loginLabel.innerText = i18n.alreadyRegisteredText
        loginLabel.style.color = "#7f7f7f"
        loginLabel.style.position = "relative"
        loginLabel.style.top = "-1px"
        loginLabel.style.marginRight = "2px"
        rightSide.appendChild(loginLabel)

        const loginLink = document.createElement("a")
        loginLink.href = normalizeResource(LOGIN_BASE_PATH)
        loginLink.innerText = i18n.loginHereText
        loginLink.className = "nooverlay"
        loginLink.style.color = "#0a5a83"
        loginLink.style.position = "relative"
        loginLink.style.top = "-1px"
        loginLink.onmouseenter = () => {
            loginLink.style.textDecoration = "underline"
        }
        loginLink.onmouseleave = () => {
            loginLink.style.textDecoration = "none"
        }
        rightSide.appendChild(loginLink)

        this.element.appendChild(rightSide)

        this.overlayClick.listen(() => {
            this.hide()
        })

        loginOverlayShown.listen(() => {
            this.hide()
        })

        // joinOverlay is only initialized if anon
        addEventListenerPoly("focus", window, () => {
            // check if user logged in on another tab when they come back
            getCb("accounts/ajax_check_session_authenticated").then((response) => {
                const data: { authenticated: boolean } = JSON.parse(response.responseText)
                if (data.authenticated) {
                    this.hide()
                    restoreJoinOverlayAnchors.fire(undefined)
                }
            }).catch(() => {})
        })
    }

    public initAndShow(joinOverlayAnchor: JoinOverlayAnchor): void {
        this.registerLaterLink.href = normalizeResource(joinOverlayAnchor.initialHref)
        this.registerLaterLink.onclick = joinOverlayAnchor.initialOnClick
        addEventListenerPoly("click", this.registerLaterLink, () => {
            this.hide()
            registerLaterLinkClicked.fire(undefined)
        })
        super.show()
        this.element.style.display = "block"

        // center join overlay with flexbox
        this.overlay.style.display = "flex"
        this.overlay.style.alignItems = "center"
        this.overlay.style.justifyContent = "center"

        document.body.style.overflow = "hidden"
        document.body.classList.add("blurred-login-overlay-shown")

        // The reason we want to have the join overlay element be the children of the overlay element
        // is so that it enables scrolling through the overlay on smaller sized window while making the
        // body element unscrollable.
        this.overlay.appendChild(this.element)
        this.overlay.style.overflow = "auto"

        this.iframe.onload = () => {
            const iframeContents = this.iframe.contentDocument

            if (this.iframe.contentDocument) {
                addEventListenerPoly("keydown", this.iframe.contentDocument.body, (ev: KeyboardEvent) => {
                    if (ev.key === "Escape") {
                        this.hide()
                    }
                })
            }

            if (iframeContents !== null) {
                const usernameInput = iframeContents.getElementById("husername")
                if (usernameInput !== null) {
                    usernameInput.focus()
                }
                const noThanksRegisterLaterLink = <HTMLAnchorElement>iframeContents.querySelector("a.next")
                noThanksRegisterLaterLink.href = normalizeResource(joinOverlayAnchor.initialHref)
                noThanksRegisterLaterLink.onclick = joinOverlayAnchor.initialOnClick
                addEventListenerPoly("click", noThanksRegisterLaterLink, () => {
                    this.hide()
                    registerLaterLinkClicked.fire(undefined)
                })
                iframeContents.querySelectorAll<HTMLAnchorElement>("a.medium_popup_link").forEach((link) => {
                    link.onclick = (event) => {
                        event.preventDefault()
                        safeWindowOpen(link.href, "_blank", mediumPopupLinkFeatures)
                    }
                })
            }
        }
        addPageAction("RegisterIframe_Shown")
    }

    private createLoadingSpinner(): HTMLElement {
        const div = document.createElement("div")
        div.style.animationName = "spin"
        div.style.position = "absolute"
        div.style.top = "50%"
        div.style.left = "50%"
        div.style.width = "30px"
        div.style.height = "31px"
        div.style.margin = "-15px 0 0 -15px"
        div.style.background = `url("${STATIC_URL}images/loading_spinner.svg")`
        div.style.backgroundSize = "cover"
        div.style.animationName = "spin"
        div.style.webkitAnimationName = "spin"
        div.style.animationDuration = "2s"
        div.style.webkitAnimationDuration = "2s"
        div.style.animationTimingFunction = "linear"
        div.style.webkitAnimationTimingFunction = "linear"
        div.style.animationIterationCount = "infinite"
        div.style.webkitAnimationIterationCount = "infinite"
        div.style.zIndex = "-1"
        return div
    }
}

export const registerLaterLinkClicked = new EventRouter<undefined>("registerLaterLinkClicked")

export const commandeerJoinOverlayAnchors = new EventRouter<undefined>("commandeerJoinOverlayAnchors", {
    listenersWarningThreshold: -1, // We are modifying links in the bio, so the number of events can be very dynamic.
    maxHistorySize: 1, // If this event is fired, it should only ever be fired once.
})

const restoreJoinOverlayAnchors = new EventRouter<undefined>("restoreJoinOverlayAnchors", {
    listenersWarningThreshold: -1, // We are modifying links in the bio, so the number of events can be very dynamic.
    maxHistorySize: 1, // If this event is fired, it should only ever be fired once.
})

let isCommandeerActive = false

export class JoinOverlayAnchor {
    public initialOnClick: HTMLAnchorElement["onclick"]
    public initialHref: string

    constructor(public anchor = document.createElement("a")) {
        if (parseQueryString(window.location.search)["join_overlay"] !== undefined) {
            const commandeerListener = () => {
                isCommandeerActive = true
                this.commandeer()
            }

            if (isCommandeerActive) {
                window.setTimeout(() => {
                    this.commandeer()
                }, 0) // Allow for the anchor to finish initialization.
            } else {
                commandeerJoinOverlayAnchors.addListener(commandeerListener, this.anchor)
            }

            const restoreListener = () => {
                isCommandeerActive = false
                this.restore()
                commandeerJoinOverlayAnchors.removeListener(commandeerListener)
                window.setTimeout(() => {
                    restoreJoinOverlayAnchors.removeListener(restoreListener)
                }, 0) // Allow the listener to finish before removing.
            }

            restoreJoinOverlayAnchors.addListener(restoreListener, this.anchor)
        }
    }

    private commandeer(): void {
        this.initialOnClick = this.anchor.onclick
        this.initialHref = normalizeResource(this.anchor.href)

        this.anchor.href = normalizeResource(SIGN_UP_BASE_PATH)
        this.anchor.onclick = (event: MouseEvent) => {
            event.preventDefault()
            showJoinOverlay.fire(this)
            restoreJoinOverlayAnchors.fire(undefined)
        }
    }

    private restore(): void {
        this.anchor.onclick = this.initialOnClick
        this.anchor.href = normalizeResource(this.initialHref)
        this.initialOnClick = null // eslint-disable-line @multimediallc/no-null-usage
    }
}

export function convertAllChildLinksToJoinOverlayAnchors(element: HTMLElement): void {
    for (const anchor of element.querySelectorAll("a:not(.nooverlay):not([href='#'])")) {
        new JoinOverlayAnchor(<HTMLAnchorElement>anchor)
    }
}

/**
 * Converts the following elements' (if applicable) child links to join overlay anchors:
 * Header, Footer, Room List, Hashtag Ticker, Gender Tabs
 */
export function convertBaseHeaderFooterLinksToJoinOverlayAnchors(): void {
    const header = document.getElementById("header")
    if (header !== null) {
        convertAllChildLinksToJoinOverlayAnchors(header)
    }
    const footer = document.getElementById("footer-holder")
    if (footer !== null) {
        convertAllChildLinksToJoinOverlayAnchors(footer)
    }
    const hashtag_ticker = document.getElementById("hashtag_ticker")
    if (hashtag_ticker !== null) {
        convertAllChildLinksToJoinOverlayAnchors(hashtag_ticker)
    }
    const gender_tabs = <HTMLCollectionOf<HTMLUListElement>>document.getElementsByClassName("sub-nav")
    if (gender_tabs.length !== 0) {
        convertAllChildLinksToJoinOverlayAnchors(gender_tabs[0])
    }
}

export function generateJoinOverlayAnchor(): HTMLAnchorElement {
    if (isRoomRoomlistSpaEligiblePage()) {
        return document.createElement("a")
    }
    return new JoinOverlayAnchor().anchor
}
