import { t } from "@lingui/macro"
import { Component } from "../../../common/defui/component"
import { EventRouter } from "../../../common/events"
import { isLinguiBasicActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { roomDossierContext } from "../../interfaces/context"
import { realtimeUserlistEnabled } from "../../roomUsers"
import { InRoomIndicator } from "./inRoomIndicator"

export const goPMList = new EventRouter<boolean>("goPMList")
export const ignoreUser = new EventRouter<void>("ignoreUser")

export class PMControlBar extends Component {
    private inRoomIndicator?: InRoomIndicator

    constructor(isFullVideoMode: boolean, otherUsername: string) {
        super()

        const isBroadcasterPM = otherUsername === roomDossierContext.getState().room
        const controlBarStyle: CSSX.Properties = {
            width: "100%",
            height: "31px",
            lineHeight: "32px",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            fontSize: "12px",
            justifyContent: "flex-start",
        }
        const backButtonStyle: CSSX.Properties = {
            marginLeft: "10px",
            cursor: "pointer",
        }
        const closeNoticeStyle: CSSX.Properties = {
            margin: "0 8px",
            display: isBroadcasterPM ? "none" : "",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }

        this.element =
            <div colorClass="PMControlBar" style={controlBarStyle} id="pm-control-bar">
                <span colorClass="backButton" style={backButtonStyle} onClick={() => {goPMList.fire(isFullVideoMode)}} data-testid="back-to-conversation-list-button">
                    {isLinguiBasicActive() ? t`Back` : i18n.back}
                </span>
                <span style={closeNoticeStyle}>
                    {isLinguiBasicActive() ? t`(Ctrl+L to close)` : i18n.ctrlLToClose}
                </span>
                {realtimeUserlistEnabled() &&
                    <InRoomIndicator
                        classRef={(inRoomIndicator) => this.inRoomIndicator = inRoomIndicator}
                        username={otherUsername}
                        style={{ marginTop: "8px" }}/>
                }
            </div>

        this.element.classList.add("pm-control-bar")
    }

    public dispose(): void {
        this.inRoomIndicator?.dispose()
    }
}

export class LoadHistoryMessagesDOM {
    private element: HTMLDivElement
    private showing = false

    constructor() {
        this.element = <div style={{ display: "none", textAlign: "center" }}>
            {isLinguiBasicActive() ? t`Loading More Messages` : i18n.loadingMoreMessages}
        </div>
    }
    getElement = (): HTMLDivElement => {
        return this.element
    }
    isShowing = (): boolean => {
        return this.showing
    }
    hideLoading = (): void => {
        this.element.style.display = "none"
        this.showing = false
    }
    showLoading = (): void => {
        this.element.style.display = "block"
        this.showing = true
    }
}
