/* eslint-disable @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return */
import { debug, error } from "@multimediallc/logging";
const Hls = window["Hls"];
let cacheLocalStorage;
const supportedDesktopBrowsers = {
    chrome: 79,
    firefox: 78,
    edge: 90,
    safari: 13,
    // Add more supported browsers and versions as needed
};
const supportedMobileBrowsers = {
    chrome: 79,
    // on iOS, Chrome has crios in the user agent string, value should match chrome
    crios: 79,
    firefox: 115,
    // firefox on iOS has fxios in the user agent string, value should match firefox
    fxios: 115,
    safari: 13,
    // to match chromium 90 like edge 90(both browsers based on chromium)
    samsungbrowser: 15,
    // Add more supported browsers and versions as needed
};
export function isLocalStorageSupported() {
    if (cacheLocalStorage === undefined) {
        const cbModernizr = "cbModernizr";
        let bool = true;
        try {
            window.localStorage.setItem(cbModernizr, cbModernizr);
            window.localStorage.removeItem(cbModernizr);
        }
        catch (e) {
            bool = false;
        }
        debug(`local storage support = ${bool}`);
        cacheLocalStorage = bool;
    }
    return cacheLocalStorage;
}
export function isHistorySupported() {
    return (window.history !== undefined && window.history.pushState !== undefined);
}
export function isAudioElementSupported() {
    try {
        const elem = document.createElement("audio");
        return !!elem.canPlayType; // eslint-disable-line @typescript-eslint/strict-boolean-expressions
    }
    catch (e) {
        return false;
    }
}
let audioContextFullySupported;
export function isAudioContextFullySupported() {
    if (audioContextFullySupported !== undefined) {
        return audioContextFullySupported;
    }
    audioContextFullySupported = false;
    let context;
    try {
        if ("webkitAudioContext" in window) {
            context = new webkitAudioContext();
        }
        else if ("AudioContext" in window) {
            context = new AudioContext();
        }
        else {
            return audioContextFullySupported;
        }
        if (context === null) {
            // this happens sometimes on iOS for some reason...
            return audioContextFullySupported;
        }
        audioContextFullySupported =
            typeof context.createGain === "function" && // gain is supported
                typeof context.decodeAudioData === "function" && // decoding buffers is supported
                typeof context.createBufferSource === "function" && // can create sources
                typeof context.createBufferSource().start === "function"; // starting sources is supported
        if (typeof context.close === "function") {
            context.close(); // eslint-disable-line @typescript-eslint/no-floating-promises
        }
    }
    catch (err) {
        error("Error checking for audio context", { reason: err.toString() });
    }
    return audioContextFullySupported;
}
let canPlayMp3;
export function isMp3Supported() {
    if (canPlayMp3 !== undefined) {
        return canPlayMp3;
    }
    const audio = document.createElement("audio");
    canPlayMp3 =
        typeof audio.canPlayType === "function" &&
            audio.canPlayType("audio/mpeg;codecs=mp3") !== "";
    return canPlayMp3;
}
export function isNativeHlsSupported() {
    const video = document.createElement("video");
    const val = video.canPlayType("application/vnd.apple.mpegurl");
    return val === "probably" || val === "maybe";
}
export function isHlsSupported() {
    return isNativeHlsSupported() || Hls.isSupported();
}
export function getRequestAnimationFrameFunction() {
    // eslint-disable-next-line no-prototype-builtins
    if (window.hasOwnProperty("requestAnimationFrame")) {
        return window["requestAnimationFrame"];
        // eslint-disable-next-line no-prototype-builtins
    }
    else if (window.hasOwnProperty("webkitRequestAnimationFrame")) {
        return window["webkitRequestAnimationFrame"];
        // eslint-disable-next-line no-prototype-builtins
    }
    else if (window.hasOwnProperty("mozRequestAnimationFrame")) {
        return window["mozRequestAnimationFrame"];
    }
    else {
        return (callback) => {
            window.setTimeout(() => {
                callback(0);
            }, 1000 / 7);
            return Date.now();
        };
    }
}
let supportsPassive;
export function isPassiveListenerSupported() {
    if (supportsPassive !== undefined) {
        return supportsPassive;
    }
    try {
        const opts = Object.defineProperty({}, "passive", {
            get: function () {
                supportsPassive = true;
            },
        });
        const noop = () => { };
        window.addEventListener("testPassive", noop, opts);
        window.removeEventListener("testPassive", noop, opts);
        if (supportsPassive === undefined) {
            supportsPassive = false;
        }
    }
    catch (e) {
        supportsPassive = false;
    }
    return supportsPassive;
}
let supportsVolumeControl;
export function isVolumeControlSupported() {
    // https://developer.apple.com/library/content/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html#//apple_ref/doc/uid/TP40009523-CH5-SW11
    if (supportsVolumeControl !== undefined) {
        return supportsVolumeControl;
    }
    try {
        const a = document.createElement("audio");
        a.volume = 0.5;
        supportsVolumeControl = a.volume === 0.5;
    }
    catch (e) {
        supportsVolumeControl = false;
    }
    return supportsVolumeControl;
}
let scrollingDocumentElement;
// Firefox incorrectly returns document.body instead of document.documentElement after refresh if the window is small.
//   Give Firefox time to set up, and then it should return the correct element.
let redoScrollingDocumentCheck = false;
window.setTimeout(() => {
    redoScrollingDocumentCheck = true;
}, 200);
export function getScrollingDocumentElement() {
    // When scrolling the page, browsers store the scrollTop property on varying elements.
    if (document.scrollingElement !== null) {
        return document.scrollingElement;
    }
    if (scrollingDocumentElement !== undefined && !redoScrollingDocumentCheck) {
        return scrollingDocumentElement;
    }
    const originalDocumentScrollTop = document.documentElement.scrollTop;
    document.documentElement.style.height = `${window.innerHeight + 1}px`;
    document.documentElement.scrollTop = originalDocumentScrollTop + 1;
    scrollingDocumentElement =
        document.documentElement.scrollTop !== originalDocumentScrollTop
            ? document.documentElement
            : document.body;
    document.documentElement.style.height = "";
    document.documentElement.scrollTop = originalDocumentScrollTop;
    redoScrollingDocumentCheck = false;
    return scrollingDocumentElement;
}
export function isPerformanceNowSupported() {
    return (window.performance !== undefined && window.performance.now !== undefined);
}
// When determining browser/OS, prefer feature detection over user agents when possible
// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
export function isChrome() {
    return ((window["chrome"] !== undefined &&
        // @ts-ignore - @types/chrome doesn't have loadTimes
        (!!window["chrome"]["loadTimes"] ||
            !!window["chrome"]["webstore"] ||
            !!window["chrome"]["runtime"])) ||
        /CriOS/i.test(navigator.userAgent));
}
// pre-Chromium Edge
export function isEdgeMS() {
    return /Edge\//i.test(navigator.userAgent);
}
export function isEdgeChromium() {
    const match = /Edg\/\d+/.exec(navigator.userAgent);
    if (match !== null) {
        return parseInt(match[0].split("/")[1], 10) >= 79;
    }
    return false;
}
export function isFirefox() {
    const match = /Firefox/.exec(navigator.userAgent);
    if (match !== null) {
        return true;
    }
    else {
        return window["InstallTrigger"] !== undefined;
    }
}
export function isSafari() {
    const notSafari = /CriOS|FxiOS/i.test(navigator.userAgent);
    if (isiOS() && /WebKit/i.test(navigator.userAgent) && !notSafari) {
        return true;
    }
    let hasRemoteNotification = false;
    if (window["safari"] !== undefined &&
        window["safari"]["pushNotification"] !== undefined) {
        hasRemoteNotification =
            window["safari"]["pushNotification"].toString() ===
                "[object SafariRemoteNotification]";
    }
    const userAgentMatch = /Safari/i.test(navigator.userAgent) &&
        /Version\/(\d+)/.test(navigator.userAgent) &&
        !notSafari;
    return userAgentMatch || hasRemoteNotification;
}
export function isSamsungTablet() {
    const uas = [
        /SM-T827R4/,
        /SM-T550/,
        /SM-T330/,
        /SM-T232/,
        /SM-T335/,
        /SM-T813/,
        /SM-T510/,
    ];
    return uas.some((ua) => {
        return ua.test(navigator.userAgent);
    });
}
export function isSamsungBrowser() {
    return /SamsungBrowser/i.test(navigator.userAgent);
}
export function isiPad() {
    return ((/iPad/i.test(navigator.userAgent) ||
        (navigator.platform === "MacIntel" &&
            navigator.maxTouchPoints > 1)) &&
        window["MSStream"] === undefined);
}
export function isiPhone() {
    return (/iPhone/i.test(navigator.userAgent) && window["MSStream"] === undefined);
}
export function isiPod() {
    return /iPod/i.test(navigator.userAgent) && window["MSStream"] === undefined;
}
export function isiOS() {
    return isiPod() || isiPhone() || isiPad();
}
export function iOSVersion() {
    if (isiOS()) {
        let version = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
        if (version === null) {
            version = navigator.userAgent.match(/Version\/(\d+)/);
            if (version === null) {
                return undefined;
            }
        }
        return parseInt(version[1]);
    }
    return undefined;
}
export function iOSVersionFull() {
    const version = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (!isiOS() || version === null) {
        return undefined;
    }
    return {
        major: parseInt(version[1]),
        minor: parseInt(version[2]),
        patch: parseInt(version[3]),
    };
}
export function isMobileDevice() {
    return (/Android|webOS|BlackBerry|IEMobile|Opera Mini|SamsungBrowser/i.test(navigator.userAgent) || isiOS());
}
export function isIE() {
    // @ts-ignore IE check on document
    return !!document["documentMode"];
}
export function isIE9OrBelow() {
    return isIE() && window["atob"] === undefined;
}
export function isIE10OrBelow() {
    // @ts-ignore - IE check on document
    return isIE() && document["__proto__"] === undefined;
}
function isVersionSupported(browser, versionToTest) {
    const supportedBrowsers = isMobileDevice()
        ? supportedMobileBrowsers
        : supportedDesktopBrowsers;
    return (supportedBrowsers.hasOwnProperty(browser) && // eslint-disable-line no-prototype-builtins
        versionToTest >= supportedBrowsers[browser]);
}
export function isSupportedBrowser() {
    // Define the list of supported browsers and their minimum versions
    const userAgent = window.navigator.userAgent.toLowerCase();
    const edgeMatch = userAgent.match(/edg\/(\d+)/);
    // user agents can have multiple matches, so we need to check for some browsers first
    if (edgeMatch) {
        // Handle the new Microsoft Edge
        const edgeVersion = parseFloat(edgeMatch[1]);
        return isVersionSupported("edge", edgeVersion);
    }
    if (isSafari()) {
        // Safari version parsing is a bit different
        const safariVersionMatch = userAgent.match(/version\/([\d.]+)/);
        if (safariVersionMatch) {
            const safariVersion = parseFloat(safariVersionMatch[1]);
            return isVersionSupported("safari", safariVersion);
        }
    }
    const userBrowserMatch = userAgent.match(/(chrome|firefox|samsungbrowser|crios|fxios)/);
    const userAgentMatch = userAgent.match(/(chrome|firefox|samsungbrowser|crios|fxios)[\s\/](\d+)/);
    if (userBrowserMatch && userAgentMatch) {
        // Handle other browsers (Chrome, Firefox, Samsung Browser)
        const browserVersion = parseFloat(userAgentMatch[2]);
        const userBrowser = userBrowserMatch[1];
        return isVersionSupported(userBrowser, browserVersion);
    }
    return false;
}
export function isPuffin() {
    return /Puffin/i.test(navigator.userAgent);
}
export function unloadEventName() {
    let event = "unload";
    if (isiOS() && isSafari()) {
        event = "pagehide";
    }
    return event;
}
export function isWebRTCSupported() {
    return (typeof navigator === "object" &&
        typeof navigator["mediaDevices"] === "object" &&
        typeof navigator["mediaDevices"]["getUserMedia"] === "function");
}
export function isWebRTCNotYetSupported() {
    // These browsers support WebRTC but not well. In the future we might support these.
    return (
    // Puffin passes these tests but then throws a "Not Supported" error
    Boolean(navigator.userAgent.match(/Puffin/i)));
}
function getEdgeMajorVersion() {
    const match = /Edg(|e|A|iOS)\/\d+/.exec(navigator.userAgent);
    if (match !== null) {
        return parseInt(match[0].split("/")[1], 10);
    }
    else {
        return 0;
    }
}
export function getBrowserAndPlatformInfo() {
    const iosVersion = iOSVersionFull();
    return {
        platform: navigator.platform,
        userAgent: navigator.userAgent,
        maxTouchPoints: navigator["maxTouchPoints"] !== undefined
            ? navigator["maxTouchPoints"]
            : 0,
        iosVersionMajor: iosVersion !== undefined ? iosVersion.major : 0,
        iosVersionMinor: iosVersion !== undefined ? iosVersion.minor : 0,
        iosVersionPatch: iosVersion !== undefined ? iosVersion.patch : 0,
        edgeVersionMajor: getEdgeMajorVersion(),
        isDefinedMSStream: window["MSStream"] !== undefined,
        isDefinedInstallTrigger: window["InstallTrigger"] !== undefined,
        isPresentChromeLoadTimes: 
        // @ts-ignore - @types/chrome doesn't have loadTimes
        window["chrome"] !== undefined && !!window["chrome"]["loadTimes"],
        isPresentChromeWebStore: window["chrome"] !== undefined && !!window["chrome"]["webstore"],
        isPresentChromeRunTime: window["chrome"] !== undefined && !!window["chrome"]["runtime"],
        safariPushNotifications: window["safari"] !== undefined
            ? window["safari"]["pushNotification"].toString()
            : "",
    };
}
let exifRespected;
function checkExifSupport() {
    return new Promise((resolve) => {
        if (exifRespected !== undefined) {
            resolve(exifRespected);
        }
        else {
            const n = new Image();
            n.onerror = () => {
                resolve(false);
            };
            n.onload = () => {
                exifRespected = 2 !== n.width;
                resolve(exifRespected);
            };
            n.src =
                "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/4QAiRXhpZgAASUkqAAgAAAABABIBAwABAAAABgASAAAAAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAABAAIDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD+/iiiigD/2Q==";
        }
    });
}
checkExifSupport()
    .then((supported) => {
    exifRespected = supported;
    debug("exif: ", exifRespected);
})
    .catch(() => { });
export function isExifRespected() {
    return exifRespected === true;
}
// Assumes unsigned
function getTypedIntegerMax(byteSize) {
    // pow(256,...) and bit shifting both overflow the JS number type
    switch (byteSize) {
        case 1:
            return 0xff;
        case 2:
            return 0xffff;
        case 4:
            return 0xffffffff;
        default:
            // 64-bit integer
            // eslint-disable-next-line no-loss-of-precision
            return 0xffffffffffffffff;
    }
}
export function getRandomString(length) {
    if (length === undefined || length < 1) {
        length = 10;
    }
    const randomValues = new Uint8Array(Math.floor(length / 2));
    getRandomIntegers(randomValues);
    return Array.from(randomValues, (i) => i.toString(36).padStart(2, "0")).join("");
}
export function insecureGetRandomInteger(byteCount) {
    const maxIntValue = getTypedIntegerMax(byteCount);
    return Math.floor(Math.random() * maxIntValue);
}
function insecureGetRandomIntegers(array) {
    if (array === null) {
        return array;
    }
    const length = array.length;
    const byteCountPerElement = array.byteLength / length;
    const maxElementValue = getTypedIntegerMax(byteCountPerElement);
    for (let i = 0; i < length; i += 1) {
        array[i] = Math.floor(Math.random() * maxElementValue);
    }
    return array;
}
// NOTE: This wrapper does not support DataView or float-based arrays as
//       an input. Additionally it will fallback on an insecure `Math.random`
//       implementation if it can't identify a usable `Crypto` instance.
export function getRandomIntegers(array) {
    let crypto;
    // IE vendor prefixes the Crypto instance
    if ("crypto" in window) {
        crypto = window.crypto;
    }
    else if ("msCrypto" in window) {
        crypto = window["msCrypto"];
    }
    // Fallback on the non-cryptographic Math.random implementation if we cant find a Crypto instance.
    if (crypto === undefined) {
        return insecureGetRandomIntegers(array);
    }
    else {
        return crypto.getRandomValues(array);
    }
}
export const isScrollBehaviorSupported = "scroll-behavior" in document.createElement("div").style;
export function hasWellSupportedEmojis() {
    // Android, iOS (iPad/iPhone), macOS
    // temporarily never show twemoji until package update is merged and deployed
    return true;
}
export function isAndroid() {
    return /Android/i.test(navigator.userAgent);
}
