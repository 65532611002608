import { getElapsedTimeString } from "@multimediallc/web-utils"
import { hasWellSupportedEmojis, isMobileDevice } from "@multimediallc/web-utils/modernizr"
import twemoji from "@twemoji/api"
import { Component } from "../../../common/defui/component"
import { applyStyles, getTextWidth, hoverEvent } from "../../../common/DOMutils"
import { ParsedEmoticonMessage } from "../../../common/parseemoticons"
import { getUsernameColorClass } from "../../../common/roomUtil"
import { closeBtnSvg, replyIconSvg } from "../../../common/svgElement"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { addColorClass, removeColorClass } from "../../colorClasses"
import { realtimeUserlistEnabled } from "../../roomUsers"
import { hideConversationsFromUser } from "./conversationList"
import { InRoomIndicator } from "./inRoomIndicator"
import type { EventRouter } from "../../../common/events"
import type { IConversationListItem } from "../../api/pm"

const MESSAGE_DIV_DEFAULT_WIDTH = "calc(100% - 36px)"

interface IConversationListItemProps {
    openConversationEvent: EventRouter<string>,
    rawData: IConversationListItem
    isDropdown: boolean
}

/**
 * A conversation that shows up in the PM or DM list.
 */
export class ConversationListItem extends Component<HTMLDivElement> {
    static CHAT_HEADER_LEFT_PX = 48
    private userColorClass: string
    private chatHeader: HTMLDivElement
    private usernameSpan: HTMLSpanElement
    private elapsedTimeContainer: HTMLDivElement
    private fromUser: string
    private otherUsername: string
    private message: string
    private numUnread: number
    private numUnreadContainer: HTMLSpanElement
    private inRoomIndicator?: InRoomIndicator
    private time: number | undefined
    private hasMedia: boolean
    private isDropdown: boolean
    private rawData: IConversationListItem
    private messageDiv: HTMLDivElement
    private closeButton?: HTMLAnchorElement

    constructor(props: IConversationListItemProps) {
        super("div", props)
    }

    initData(props: IConversationListItemProps): void {
        this.isDropdown = props.isDropdown
        this.userColorClass = getUsernameColorClass(props.rawData.otherUser)
        this.otherUsername = props.rawData.otherUser.username
        this.fromUser = props.rawData.fromUsername
        this.message = props.rawData.message
        this.hasMedia = props.rawData.hasMedia
        this.numUnread = props.rawData.numUnread
        this.time = props.rawData.time
        this.isDropdown = props.isDropdown
        this.rawData = props.rawData
    }

    public getRawData(): IConversationListItem {
        return this.rawData
    }

    initUI(props: IConversationListItemProps): void {
        const conversationListItemStyle: CSSX.Properties = {
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            cursor: "pointer",
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            fontSize: "12px",
            height: "40px",
            lineHeight: "14px",
            overflowX: "hidden",
            padding: "8px",
            position: "relative",
            textAlign: "left",
        }
        const avatarBubbleStyle: CSSX.Properties = {
            marginTop: "-16px",
            position: "absolute",
            top: "50%",
        }
        const chatHeaderStyle: CSSX.Properties = {
            display: "inline-block",
            left: `${ConversationListItem.CHAT_HEADER_LEFT_PX}px`,
            position: "absolute",
            verticalAlign: "bottom",
            whiteSpace: "nowrap",
        }
        const nameStyle: CSSX.Properties = {
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            verticalAlign: "bottom",
        }
        const messageStyle: CSSX.Properties = {
            bottom: "8px",
            paddingLeft: `${ConversationListItem.CHAT_HEADER_LEFT_PX - 8}px`,
            overflow: "hidden",
            position: "absolute",
            whiteSpace: "nowrap",
            width: MESSAGE_DIV_DEFAULT_WIDTH,
            boxSizing: "border-box",
        }
        const timeStyle: CSSX.Properties = {
            fontSize: "10px",
            position: "absolute",
            right: "8px",
            textAlign: "right",
            top: "8px",
        }
        const inRoomIndicatorStyle: CSSX.Properties = {
            verticalAlign: "middle",
            position: "relative",
            top: "-1px",
        }
        const replyIconContainerStyle: CSSX.Properties = {
            display: "inline-block",
            verticalAlign: "top",
        }

        const elapsedTimeString = this.getElapsedTimeString()
        const messageSpan = this.getMessagePreview()
        const isReply = this.otherUsername !== this.fromUser
        if (isReply) {
            messageSpan.style.maxWidth = "calc(100% - 14px)"
        }

        const hasUnreadAndMessage = this.numUnread > 0 && (this.message !== "" || this.hasMedia)
        this.numUnreadContainer = <span data-testid="unread-message-count">
            {hasUnreadAndMessage ? ` (${this.numUnread})` : ""}
        </span>
        this.elapsedTimeContainer = <div className="timeString" style={timeStyle} data-testid="message-timestamp">{elapsedTimeString}</div>
        this.usernameSpan = this.getUsernameSpan(nameStyle)
        this.updateUsernameMaxWidth()

        this.element = (
            <div colorClass={["conversationListItem", `${hasUnreadAndMessage ? "unreadBg" : ""}`]}
                 style={conversationListItemStyle}
                 data-testid="conversation-list-item"
                 onClick={() => {
                     props.openConversationEvent.fire(this.otherUsername)
                 }}
            >
                <AvatarBubble name={this.otherUsername} style={avatarBubbleStyle}
                              color="" size="32px" userColorClass={this.userColorClass} />
                <div style={chatHeaderStyle} ref={(c: HTMLDivElement) => this.chatHeader = c}>
                    {this.usernameSpan}
                    {this.numUnreadContainer}
                    {(realtimeUserlistEnabled() && !this.isDropdown) &&
                        <InRoomIndicator
                            classRef={(inRoomIndicator) => this.inRoomIndicator = inRoomIndicator}
                            username={this.otherUsername}
                            style={inRoomIndicatorStyle}/>
                    }
                </div>
                {this.elapsedTimeContainer}
                <div colorClass="messageDiv" style={messageStyle} ref={(c: HTMLDivElement) => this.messageDiv = c}>
                    {isReply && this.message !== "" ? replyIconSvg("replyIconSvg", replyIconContainerStyle) : ""}
                    {messageSpan}
                </div>
            </div>
        )
    }

    render(): HTMLElement {
        return this.element
    }

    public hide(): void {
        this.parent?.removeChild(this)
        this.element.remove()
        this.dispose()
    }

    public dispose(): void {
        this.inRoomIndicator?.dispose()
    }

    protected repositionChildren(): void {
        super.repositionChildren()
        this.hideElapsedTimeResponsive()
    }

    protected afterDOMConstructed(): void {
        super.afterDOMConstructed()
        this.hideElapsedTimeResponsive()
    }

    public updateTimeContainer(): void {
        const elapsedTimeString = this.time !== undefined && !this.isEmptyMessage() ? getElapsedTimeString(new Date(this.time)) : ""
        this.elapsedTimeContainer.innerText = elapsedTimeString
        this.updateUsernameMaxWidth()
        this.updateCloseButtonTop()
    }

    private updateUsernameMaxWidth(): void {
        if (!this.isDropdown) {
            return
        }
        const maxWidth = 228 - getTextWidth(this.elapsedTimeContainer.textContent ?? "", this.elapsedTimeContainer)
                             - getTextWidth(this.numUnreadContainer.textContent ?? "", this.numUnreadContainer)
        this.usernameSpan.style.maxWidth = `${maxWidth}px`
    }

    private getUsernameSpan(nameStyle: CSSX.Properties): HTMLSpanElement {
        return <span colorClass={this.userColorClass} style={nameStyle} data-testid="conversation-list-username">{this.otherUsername}</span>
    }

    public addCloseBtn(): void {
        this.closeButton = closeBtnSvg()
        const closeButton = this.closeButton // local copy so hoverEvent listener doesn't need to check undefined
        applyStyles(closeButton, { position: "absolute", right: "8px" })

        closeButton.onclick = (evt) => {
            evt.stopPropagation()
            evt.preventDefault()
            hideConversationsFromUser(this.otherUsername, this.isDropdown)
        }

        if (isMobileDevice()) {
            closeButton.style.display = "block"
            this.messageDiv.style.width = `calc(${MESSAGE_DIV_DEFAULT_WIDTH} - 20px)`
        } else {
            closeButton.style.display = "none"
            hoverEvent(this.element).listen((isHover) => {
                if (isHover) {
                    closeButton.style.display = "block"
                    this.elapsedTimeContainer.style.display = "none"
                    this.messageDiv.style.width = `calc(${MESSAGE_DIV_DEFAULT_WIDTH} - 20px)`
                } else {
                    closeButton.style.display = "none"
                    this.elapsedTimeContainer.style.display = "block"
                    this.messageDiv.style.width = MESSAGE_DIV_DEFAULT_WIDTH
                }
            })
        }
        this.updateCloseButtonTop()
        this.element.appendChild(closeButton)
    }

    private updateCloseButtonTop(): void {
        if (this.closeButton === undefined) {
            return
        }
        if (isMobileDevice() && this.elapsedTimeContainer.textContent !== "") {
            this.closeButton.style.top =  "50%"
        } else {
            this.closeButton.style.top = "calc(50% - 8px)"
        }
    }

    /**
     * Hide the elapsed time div if the list item gets too small
     */
    private hideElapsedTimeResponsive(): void {
        if (this.element.clientWidth > 0) {
            const hasOverlap = this.chatHeader.clientWidth > this.element.clientWidth - ConversationListItem.CHAT_HEADER_LEFT_PX - 8 - this.elapsedTimeContainer.clientWidth
            this.elapsedTimeContainer.style.visibility = hasOverlap ? "hidden" : ""
        }
    }

    public getOtherUsername(): string {
        return this.otherUsername
    }

    public getNumUnread(): number {
        return this.numUnread
    }

    public setNumUnread(numUnread: number): void {
        this.numUnread = numUnread
        if (this.numUnread === 0) {
            this.numUnreadContainer.innerText = ""
            removeColorClass(this.element, "unreadBg")
        } else {
            this.numUnreadContainer.innerText = ` (${this.numUnread})`
            addColorClass(this.element, "unreadBg")
        }
    }

    public getUserColorClass(): string {
        return this.userColorClass
    }

    private getMessagePreview(): HTMLSpanElement {
        if (this.message === "" && this.hasMedia) {
            return <span>{i18n.imageAttached}</span>
        }

        const parsedEmoticonMessage: ParsedEmoticonMessage = new ParsedEmoticonMessage(this.message)
        const firstStringPart = parsedEmoticonMessage.stringParts()[0]
        const messageSpanStyle: CSSX.Properties = {
            maxWidth: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "inline-block",
        }
        const span = <span style={messageSpanStyle} data-testid="message-preview">{ firstStringPart.split("\n")[0] }</span>

        for (let i = 1; i < parsedEmoticonMessage.stringParts().length; i += 1) {
            const noEmoticonSpan = <span style={{ cursor: "pointer" }}>
                { `:${parsedEmoticonMessage.emoticons()[i - 1].name}` }
            </span>
            span.appendChild(noEmoticonSpan)

            if (parsedEmoticonMessage.stringParts()[i] !== "") {
                const stringPartSpan = <span>{ parsedEmoticonMessage.stringParts()[i] }</span>
                span.appendChild(stringPartSpan)
            }
        }
        return (!hasWellSupportedEmojis() ? twemoji.parse(span) : span)
    }

    private isEmptyMessage(): boolean {
        return this.message === "" && !this.hasMedia
    }

    private getElapsedTimeString(): string {
        return this.time !== undefined && !this.isEmptyMessage() ? getElapsedTimeString(new Date(this.time)) : ""
    }
}

/**
 * Avatar bubble that shows up next to each conversation.
 * @param props.name username of the sender.
 * @param props.color the color the bubble should be.
 * @param props.style Additional styles that you want to be added. Will override avatarBubbleStyle.
 */
const AvatarBubble = (props: { name: string, color?: string, size: string, style?: CSSX.Properties, userColorClass?: string }): HTMLDivElement => {
    const avatarBubbleStyle: CSSX.Properties = {
        width: props.size,
        height: props.size,
        borderRadius: "50%",
        background: props.color,
        textAlign: "center",
        display: "table-cell",
        fontFamily: "UbuntuLight, Tahoma, Arial, Helvetica, sans-serif",
        fontSize: "16px",
        lineHeight: "14px",
        margin: "auto",
        verticalAlign: "middle",
    }
    return (
        <div colorClass="avatarBubble" style={{ ...{ position: "relative" }, ...props.style }}>
            <div colorClass={props.userColorClass} style={avatarBubbleStyle}>{props.name.charAt(0).toLocaleUpperCase()}</div>
        </div>
    )
}
