import { isiOS } from "@multimediallc/web-utils/modernizr"
import { addColorClass } from "../../cb/colorClasses"
import { NewMessageLine } from "../../cb/ui/newMessageLine"
import { ScrollDownButton } from "../../cb/ui/scrollDownButton"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { roomCleanup, roomLoaded } from "../context"
import { Debouncer, DebounceTypes } from "../debouncer"
import { Component } from "../defui/component"
import { stopScrollMomentum } from "../DOMutils"
import { EventRouter, ListenerGroup } from "../events"
import { addChatHistoryPageActionsScollListener, isChatScrollingPageactionsActive, isReduceChatHistoryActive, isScrollDownNoticeActive } from "../featureFlagUtil"
import { isFullscreen } from "../fullscreen"
import { setPureChatColor } from "../fullvideolib/pureChatUtil"
import { insertByTimestamp } from "../messageToDOM"
import { MobileRoomNotice } from "../roomNotice"
import { RoomNoticeDeclutterer } from "../roomNoticeDeclutterer"
import { styleTransition } from "../safeStyle"
import { maxMessageHistory } from "../theatermodelib/chatTabContents"
import { createRoomPhotoMessage } from "../theatermodelib/messageToDOM"
import { userChatSettingsUpdate } from "../theatermodelib/userActionEvents"
import { LayoutConstraints } from "./layoutConstraints"
import {
    mobileConversationLinkFullscreenToggle,
    mobileFullscreenSendChat,
    mobilePureChatDrag,
    mobilePureChatDragEnd,
    mobilePureChatTap,
    mobileVideoControlsTap,
} from "./mobileControlsEvents"
import { scrollFix } from "./scrollFix"
import { getViewportHeight, getViewportWidth } from "./viewportDimension"
import { isPortrait, screenOrientationChanged } from "./windowOrientation"
import type { ConversationType } from "../../cb/api/pm"
import type { IPrivateMessage, IRoomNotice } from "../messageInterfaces"
import type { RoomNotice } from "../roomNotice"
import type { IHtmlCreateEvent } from "../ui/interfaces"

const messageDisappearSeconds = 15
const playerVideoControlsHeight = 56
const messageFadeOut = 1000
const messageFadeIn = 400
const FADE_DURATION = 175
const RESIZE_WIDTH = 50
const RESIZE_OFFSET = 0.6
const CHAT_TOP_MARGIN = 4
const ICON_HEIGHT = 13
const ICON_WIDTH = 7
const ICON_PADDING = 4
const RESIZE_INDICATOR_WIDTH = ICON_HEIGHT + ICON_PADDING * 2
const ICON_MARGIN = 1
const LANDSCAPE_CHAT_MARGIN = 20
const LOGO_HEIGHT = 30
const CLOSE_CHAT_EXTRA_PADDING = 30

export const mobilePureChatChange = new EventRouter<boolean>("mobilePureChatChange")

export interface IMirrorableChatComponent {
    addMessageHTMLEvent: EventRouter<IHtmlCreateEvent>
    addNoticeEvent: EventRouter<IRoomNotice>
    addPhotoMessageEvent: EventRouter<IPrivateMessage>
    removeMessagesForUserEvent: EventRouter<string>
}

export class MobilePureChat extends Component {
    private innerDiv: HTMLDivElement
    private pendingTimeouts: number[] = []
    private listenerGroup: ListenerGroup
    private isVisible = false
    private desiredPortraitHeight = this.deviceLength() / 4
    private desiredLandscapeWidth = this.deviceLength() / 4
    private portraitHeight = this.deviceLength() / 4
    private landscapeWidth = this.deviceLength() / 4
    private resizeDragDiv: HTMLDivElement
    private controlsContainerDiv: HTMLDivElement
    private resizeIndicatorDiv: HTMLDivElement
    private resizeIndicatorImg: HTMLImageElement
    private closeChatContainer: HTMLDivElement
    private closeChatDiv: HTMLDivElement
    private closeChatImg: HTMLImageElement
    private areEphemeralsVisible = false
    private backgroundDelay = 0
    private heightDuration = 0
    private widthDuration = 0
    public layoutConstraints = new LayoutConstraints(this.constructor.name)
    public inputFocusOffset = 0
    private roomNoticeDeclutterer: RoomNoticeDeclutterer
    private newMessageNotice?: NewMessageLine
    private scrollDownButton?: ScrollDownButton
    private scrollToBottomDebouncer = new Debouncer(() => this.scrollToBottom(), { bounceLimitMS: 0, debounceType: DebounceTypes.throttle })

    constructor(private chatComponentToMirror: IMirrorableChatComponent) {
        super()
        addColorClass(this.element, "hasDarkBackground")
        this.element.style.position = "absolute"
        this.element.style.height = ""
        this.element.style.fontSize = "10px"
        this.element.style.textShadow = "1px 0 1px #000, -1px 0 1px #000, 0 -1px 1px #000, 0 1px 1px #000"
        this.element.style.bottom = `${playerVideoControlsHeight}px`
        this.element.style.overflow = "visible"
        this.element.style.overflowWrap = "break-word"

        // Wrapper on non-resizeDragDiv contents so that scrollDownButton is affected by overflow: hidden but resizeDragDiv is not
        const contents = document.createElement("div")
        contents.style.position = "relative"
        contents.style.overflow = "hidden"
        contents.style.height = "100%"
        this.element.appendChild(contents)

        this.innerDiv = document.createElement("div")
        scrollFix(this.innerDiv)
        this.innerDiv.style.position = ""
        this.innerDiv.style.bottom = "0"
        this.innerDiv.style["-webkit-text-size-adjust"] = "none" // prevent iPhone auto-adjusting text size
        if (isScrollDownNoticeActive()) {
            this.innerDiv.style.overflowY = "auto"
        }
        this.innerDiv.style.overflowX = "hidden"
        this.innerDiv.style.height = "100%"
        contents.appendChild(this.innerDiv)

        this.createResizeDragDiv()
        this.element.appendChild(this.resizeDragDiv)

        this.listenerGroup = new ListenerGroup()

        if (isScrollDownNoticeActive()) {
            this.scrollDownButton = new ScrollDownButton({
                scrollToBottom: () => this.scrollToBottom(),
                bottomStyle: "4px",
            })
            contents.appendChild(this.scrollDownButton.element)

            this.newMessageNotice = new NewMessageLine({
                getUnreadCount: () => this.scrollDownButton?.getUnreadCount(),
                isConversationShowing: () => this.isVisible,
                isScrolledUp: () => this.isScrolledUp(),
                setParentScrollTop: (oldScrollTop: number) => this.setScrollTop(oldScrollTop),
                scrollParentDiv: this.innerDiv,
            })
        }

        this.initNoticeDeclutterer()

        addEventListenerPoly("scroll", this.innerDiv, (ev) => {
            if (this.isScrolledUp()) {
                this.scrollDownButton?.showElement()
            } else {
                this.scrollDownButton?.hideElement()
                this.scrollDownButton?.clearUnread()
            }
        })

        if (isChatScrollingPageactionsActive()) {
            addChatHistoryPageActionsScollListener(this.innerDiv, this.innerDiv)
        }


        chatComponentToMirror.addMessageHTMLEvent.listen((messageFactory) => {
            this.addMessage(messageFactory.makeByCloning())
        }).addTo(this.listenerGroup)

        chatComponentToMirror.addNoticeEvent.listen(roomNoticeData => {
            this.addNotice(roomNoticeData)
        }).addTo(this.listenerGroup)

        chatComponentToMirror.addPhotoMessageEvent.listen(messageData => {
            this.addRoomPhotoMessage(messageData)
        }).addTo(this.listenerGroup)

        chatComponentToMirror.removeMessagesForUserEvent.listen((user) => {
            this.handleRemoveMessages(user)
        }).addTo(this.listenerGroup)

        roomCleanup.listen(() => {
            this.clear()
            this.roomNoticeDeclutterer.onRoomCleanup()
        }).addTo(this.listenerGroup)

        roomLoaded.listen((context) => {
            this.element.style.fontSize = context.dossier.userChatSettings.fontSize
            this.setLineHeight()
        }).addTo(this.listenerGroup)

        userChatSettingsUpdate.listen((userChatSettings) => {
            this.element.style.fontSize = userChatSettings.fontSize
            this.setLineHeight()
        }).addTo(this.listenerGroup)

        const showMessagesDebouncer = new Debouncer(() => {
            this.showMessages()
        }, { bounceLimitMS: messageDisappearSeconds * 1000 / 2, debounceType: DebounceTypes.trailThrottle })

        this.element.ontouchstart = (ev: TouchEvent) => {
            showMessagesDebouncer.callFunc()
        }

        this.innerDiv.ontouchstart = () => {
            showMessagesDebouncer.callFunc()
        }
        this.innerDiv.ontouchmove = () => {
            showMessagesDebouncer.callFunc()
        }
        this.innerDiv.onclick = (e: Event) => {
            this.toggleEphemeralsVisibility()
            if ((e.target as HTMLElement).dataset.messagetype !== "shortcode") {
                mobilePureChatTap.fire(this.areEphemeralsVisible)
            }
        }

        let lastX: number
        let lastY: number
        let wasIndicatorVisible: boolean
        this.resizeDragDiv.ontouchstart = (ev: TouchEvent) => {
            if (ev.touches.length !== 1) {
                ev.preventDefault()
            }
            wasIndicatorVisible = this.areEphemeralsVisible
            lastX = ev.touches[0].clientX
            lastY = ev.touches[0].clientY
            if (isPortrait()) {
                this.heightDuration = 0
                this.updateTransitions()
            } else {
                this.widthDuration = 0
                this.updateTransitions()
            }
        }
        this.resizeDragDiv.ontouchmove = (ev: TouchEvent) => {
            ev.preventDefault()
            if (ev.touches.length < 1 || ev.touches.length > 2) {
                return
            }
            const x = ev.touches[0].clientX
            const y = ev.touches[0].clientY
            if (isPortrait()) {
                this.setPortraitHeight(this.portraitHeight + (lastY - y))
            } else {
                this.setLandscapeWidth(this.landscapeWidth + (lastX - x))
            }
            lastX = x
            lastY = y
            showMessagesDebouncer.callFunc()
            this.showEphemerals()
            mobilePureChatDrag.fire(undefined)
        }
        this.resizeDragDiv.ontouchend = (ev: TouchEvent) => {
            if (ev.touches.length > 0) {
                return
            }
            if (!wasIndicatorVisible) {
                this.hideEphemerals(160)
            }
            mobilePureChatDragEnd.fire(undefined)
        }
        this.resizeDragDiv.onclick = () => {
            this.toggleEphemeralsVisibility()
            mobilePureChatTap.fire(this.areEphemeralsVisible)
        }

        mobileVideoControlsTap.listen(() => {
            if (this.areEphemeralsVisible) {
                this.toggleEphemeralsVisibility()
            }
        }).addTo(this.listenerGroup)

        mobileFullscreenSendChat.listen(() => {
            if (this.isChatHidden()) {
                this.unhideChat()
            }
            this.scrollToBottom()
        }).addTo(this.listenerGroup)

        this.layoutConstraints.setOnUpdate(() => {
            this.repositionChildren()
        })

        screenOrientationChanged.listen(() => {
            this.backgroundDelay = 0
            this.heightDuration = 0
            this.widthDuration = 0
            this.updateTransitions()
            if (isScrollDownNoticeActive()) {
                // Clear new notice line when orientation is changed.
                this.newMessageNotice?.remove()
            }

            if (isFullscreen()) {
                // Timeout bc scrollTop strangely doesn't/can't be set immediately
                // after oriention change and when in native fullscreen
                window.setTimeout(() => {this.scrollToBottom()}, 200)
            } else {
                this.scrollToBottom()
            }
        }).addTo(this.listenerGroup)

        this.element.style.display = "none"
        this.repositionChildren()
    }

    private initNoticeDeclutterer(): void {
        this.roomNoticeDeclutterer = new RoomNoticeDeclutterer({
            appendNotice: (notice: RoomNotice, countsForUnread: boolean) => this.addMessage(notice.element, countsForUnread),
            appendSentinelDiv: (div: HTMLDivElement) => this.element.appendChild(div),
        })
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
        this.roomNoticeDeclutterer.dispose()
    }

    private clear(): void {
        for (const timeout of this.pendingTimeouts) {
            clearTimeout(timeout)
        }
        this.pendingTimeouts = []
        while (this.innerDiv.firstChild !== null) {
            this.innerDiv.removeChild(this.innerDiv.firstChild)
        }
    }

    private setLineHeight(): void {
        this.innerDiv.style.lineHeight = `${Number(this.element.style.fontSize.slice(0, -2)) + 7}pt`
    }

    private addTimeout(handler: () => void, timeout: number): void {
        const id = window.setTimeout(() => {
            const i = this.pendingTimeouts.indexOf(id)
            if (i >= 0) {
                this.pendingTimeouts.splice(i, 1)
            }
            handler()
        }, timeout)
        this.pendingTimeouts.push(id)
    }

    private showBackground(): void {
        this.backgroundDelay = 0
        this.updateTransitions()
        this.element.style.background = "rgba(255, 255, 255, 0.25)"
    }

    private hideBackground(delay = 0): void {
        this.backgroundDelay = delay
        this.updateTransitions()
        this.element.style.background = "transparent"
    }

    // eslint-disable-next-line complexity
    private addMessage(message: HTMLDivElement, countsForUnread = true): void {
        const oldScrollTop = this.innerDiv.scrollTop
        setPureChatColor(message)
        const messageAnchor = message.querySelector("a")
        if (messageAnchor !== null && messageAnchor.classList.contains("mobileChatLink")) {
            const conversationType = messageAnchor.getAttribute("data-conversation-type") as ConversationType
            addEventListenerPoly("click", messageAnchor, () => {
                mobileConversationLinkFullscreenToggle.fire(conversationType)
            })
        }
        message.style.fontSize = ""
        message.style.lineHeight = ""
        message.style.display = "inline-block"
        message.style.verticalAlign = "bottom"
        message.style.overflowWrap = "break-word"
        const wasScrolledUp = this.isScrolledUp()
        if (countsForUnread && (wasScrolledUp || !this.isVisible)) {
            this.scrollDownButton?.incUnread()
        }
        if (this.newMessageNotice?.shouldAppendNewMessageNotice(countsForUnread, false) === true) {
            insertByTimestamp(this.newMessageNotice.element, this.innerDiv)
            this.resetMessageHideTimeout(this.newMessageNotice.element)
        }

        insertByTimestamp(message, this.innerDiv)
        this.resetMessageHideTimeout(message)

        // Remove disable-next-line complexity when removing flag
        if (isReduceChatHistoryActive()) {
            let overflow = this.innerDiv.childElementCount - maxMessageHistory()
            for (; overflow > 0 ; overflow -= 1) {
                const firstNode = this.innerDiv.firstElementChild
                if (firstNode !== null) {
                    this.innerDiv.removeChild(firstNode)
                }
            }
        }

        if (!wasScrolledUp) {
            this.scrollToBottom()
        } else {
            this.newMessageNotice?.maybeScrollJump(oldScrollTop)
        }
    }

    private addRoomPhotoMessage(messageData: IPrivateMessage): void {
        const photoMessage = createRoomPhotoMessage(messageData)
        if (photoMessage !== undefined) {
            this.addMessage(photoMessage, false)
        }
    }

    private addNotice(roomNoticeData: IRoomNotice): void {
        const roomNotice = new MobileRoomNotice({
            roomNoticeData,
            isChatScrolledToBottom: () => !this.isScrolledUp(),
            scrollChatToBottom: () => this.debouncedScrollToBottom(),
            getChatScrollTop: () => this.getScrollTop(),
            setChatScrollTop: (top: number) => this.setScrollTop(top),
        })
        this.roomNoticeDeclutterer.addRoomNotice(roomNotice)
    }

    private isScrolledUp(): boolean {
        return this.innerDiv.scrollTop <= this.innerDiv.scrollHeight - (this.innerDiv.offsetHeight + 20)
    }

    public scrollToBottom(): void {
        if (isiOS()) {
            stopScrollMomentum(this.innerDiv)
        }

        this.repositionChildren()
        this.innerDiv.scrollTop = this.innerDiv.scrollHeight
        this.scrollDownButton?.hideElement()
    }

    private debouncedScrollToBottom(): void {
        this.scrollToBottomDebouncer.callFunc()
    }

    private getScrollTop(): number {
        return this.innerDiv.scrollTop
    }

    private setScrollTop(top: number): void {
        this.innerDiv.scrollTo({ top: top })
    }

    private showMessages(): void {
        for (const timeout of this.pendingTimeouts) {
            clearTimeout(timeout)
        }
        for (const message of this.innerDiv.children) {
            this.resetMessageHideTimeout(message as HTMLDivElement, true)
        }
    }

    private resetMessageHideTimeout(message: HTMLDivElement, fadeIn = false): void {
        styleTransition(message, `opacity ${fadeIn ? messageFadeIn : 0}ms`)
        message.style.opacity = "1"
        this.addTimeout(() => {
            this.hideMessage(message)
        }, messageDisappearSeconds * 1000)
    }

    private hideMessage(message: HTMLDivElement): void {
        if (this.isScrolledUp()) {
            this.addTimeout(() => {
                this.hideMessage(message)
            }, messageDisappearSeconds * 1000)
        } else {
            styleTransition(message, `opacity ${messageFadeOut}ms`)
            message.style.opacity = "0"
        }
    }

    public setVisible(isVisible: boolean): void {
        if (this.isVisible === isVisible) {
            return
        }
        this.isVisible = isVisible
        this.heightDuration = 0
        this.widthDuration = 0
        this.updateTransitions()
        // Clear any previous new notice line when purechat is opened / switching to fullscreen mode
        this.newMessageNotice?.remove()
        window.setTimeout(() => {
            this.scrollToBottom()
        }, Math.max(this.heightDuration, this.widthDuration) + 100)

        const newConstraints = {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            transitionTime: 0,
        }
        if (this.isVisible) {
            this.showEphemerals()
            this.showMessages()
            newConstraints.bottom = this.calculateTotalChatHeight()
            newConstraints.right = this.calculateTotalChatWidth()
        }
        this.layoutConstraints.setConstraints(newConstraints)
        mobilePureChatChange.fire(isVisible)
    }

    private calculateTotalChatHeight(): number {
        return Math.round(this.portraitHeight + playerVideoControlsHeight + (RESIZE_WIDTH * RESIZE_OFFSET) + CHAT_TOP_MARGIN)
    }

    private calculateTotalChatWidth(): number {
        return Math.round(this.landscapeWidth + LANDSCAPE_CHAT_MARGIN)
    }

    private deviceLength(): number {
        return isPortrait() ? getViewportHeight() : getViewportWidth()
    }

    public setPortraitHeight(newHeight: number, transitionTime = 0): void {
        this.desiredPortraitHeight = newHeight
        const isScrolledDown = !this.isScrolledUp()
        const oldHeight = this.portraitHeight
        const resizeDivHeight = RESIZE_WIDTH * RESIZE_OFFSET
        const maxHeight = this.deviceLength() - playerVideoControlsHeight - resizeDivHeight - CHAT_TOP_MARGIN - Math.max(LOGO_HEIGHT, this.layoutConstraints.top())

        newHeight = Math.min(newHeight, maxHeight)
        newHeight = Math.max(newHeight, 0)
        newHeight = Math.round(newHeight)
        this.portraitHeight = newHeight

        if (isFullscreen()) {
            this.element.style.height = `min(calc(var(--vh, 1vh) * 100 - ${playerVideoControlsHeight + 80}px), ${this.portraitHeight}px)`
        } else {
            this.element.style.height = `${this.portraitHeight}px`
        }

        if (newHeight === 0) {
            this.hideCloseChatDiv()
        } else {
            this.showCloseChatDiv()
        }

        if (newHeight === oldHeight) {
            return
        }

        if (isScrolledDown || oldHeight === 0) {
            this.scrollToBottom()
        } else {
            this.innerDiv.scrollTop += (oldHeight - newHeight)
        }
        this.layoutConstraints.setConstraints({
            top: 0,
            bottom: this.isVisible ? this.calculateTotalChatHeight() : 0,
            left: 0,
            right: this.isVisible ? this.calculateTotalChatWidth() : 0,
            transitionTime,
        })
    }

    private setLandscapeWidth(newWidth: number, transitionTime = 0): void {
        this.desiredLandscapeWidth = newWidth
        const isScrolledDown = !this.isScrolledUp()
        const iOS_swipeZoneWidth = 50
        const oldWidth = this.landscapeWidth
        const maxWidth = this.deviceLength() - (RESIZE_WIDTH * RESIZE_OFFSET) - LANDSCAPE_CHAT_MARGIN - Math.max(iOS_swipeZoneWidth, this.layoutConstraints.left())

        newWidth = Math.min(newWidth, maxWidth)
        newWidth = Math.max(newWidth, 0)
        newWidth = Math.round(newWidth)
        this.landscapeWidth = newWidth
        this.element.style.width = `${this.landscapeWidth}px`

        if (newWidth === 0) {
            this.hideCloseChatDiv()
        } else if (window.innerHeight < 150) {
            // The pureChat resizer will overlap the input if the window is too small
            this.hideCloseChatDiv()
            if (window.innerHeight < 75) {
                this.hideEphemerals()
            }
        } else {
            this.showCloseChatDiv()
        }

        if (newWidth === oldWidth) {
            return
        }

        if (isScrolledDown || oldWidth === 0) {
            this.scrollToBottom()
        }
        this.layoutConstraints.setConstraints({
            top: 0,
            bottom: this.isVisible ? this.calculateTotalChatHeight() : 0,
            left: 0,
            right: this.isVisible ? this.calculateTotalChatWidth() : 0,
            transitionTime,
        })
    }

    private hideCloseChatDiv(): void {
        this.closeChatContainer.style.display = "none"
        if (isPortrait()) {
            this.resizeIndicatorDiv.style.height = `${RESIZE_INDICATOR_WIDTH}px`
            this.resizeIndicatorDiv.style.width = `${getViewportWidth() - ICON_MARGIN * 2}px`
        } else {
            this.resizeIndicatorDiv.style.height = "100%"
            this.resizeIndicatorDiv.style.width = `${RESIZE_INDICATOR_WIDTH}px`
        }
    }

    private showCloseChatDiv(): void {
        if (isPortrait()) {
            this.resizeIndicatorDiv.style.height = `${RESIZE_INDICATOR_WIDTH}px`
            this.resizeIndicatorDiv.style.width = `${getViewportWidth() - RESIZE_INDICATOR_WIDTH - ICON_MARGIN * 4}px`
        } else {
            const chatRect = this.resizeDragDiv.getBoundingClientRect()
            this.resizeIndicatorDiv.style.height = `${chatRect.height - RESIZE_INDICATOR_WIDTH - ICON_MARGIN * 2}px`
            this.resizeIndicatorDiv.style.width = `${RESIZE_INDICATOR_WIDTH}px`
        }
        this.closeChatContainer.style.display = "inline-block"
    }

    private createResizeDragDiv(): void {
        this.resizeDragDiv = document.createElement("div")
        this.controlsContainerDiv = document.createElement("div")
        this.resizeIndicatorDiv = document.createElement("div")
        this.resizeIndicatorImg = document.createElement("img")
        this.closeChatContainer = document.createElement("div")
        this.closeChatDiv = document.createElement("div")
        this.closeChatImg = document.createElement("img")

        this.resizeDragDiv.appendChild(this.controlsContainerDiv)
        this.controlsContainerDiv.appendChild(this.resizeIndicatorDiv)
        this.controlsContainerDiv.appendChild(this.closeChatContainer)
        this.closeChatContainer.appendChild(this.closeChatDiv)
        this.resizeIndicatorDiv.appendChild(this.resizeIndicatorImg)
        this.closeChatDiv.appendChild(this.closeChatImg)

        this.resizeDragDiv.style.position = "absolute"
        this.resizeDragDiv.style.bottom = "0"

        this.controlsContainerDiv.style.verticalAlign = "middle"
        this.controlsContainerDiv.style.width = "100%"
        this.controlsContainerDiv.style.position = "absolute"

        this.resizeIndicatorDiv.style.display = "inline-block"
        this.resizeIndicatorDiv.style.position = "absolute"
        this.resizeIndicatorDiv.style.left = "0"
        this.resizeIndicatorDiv.style.padding = `${ICON_PADDING}px`
        this.resizeIndicatorDiv.style.borderRadius = "4px"
        this.resizeIndicatorDiv.style.opacity = "1"
        this.resizeIndicatorDiv.style.boxSizing = "border-box"
        this.resizeIndicatorDiv.style.background = "rgba(0, 0, 0, 0.3)"
        this.resizeIndicatorDiv.classList.add("resizeIndicatorDiv")

        this.closeChatContainer.style.padding = `${CLOSE_CHAT_EXTRA_PADDING}px`
        this.closeChatContainer.style.display = "inline-block"
        this.closeChatContainer.style.position = "absolute"
        this.closeChatContainer.style.margin = "0"
        this.closeChatContainer.style.boxSizing = "border-box"
        this.closeChatContainer.style.fontSize = "9pt"
        this.closeChatContainer.style.lineHeight = "14px"
        this.closeChatContainer.style.top = `${-CLOSE_CHAT_EXTRA_PADDING + RESIZE_INDICATOR_WIDTH / 2}px`
        this.closeChatContainer.style.right = `${-CLOSE_CHAT_EXTRA_PADDING + ICON_MARGIN + RESIZE_INDICATOR_WIDTH / 2}px`

        this.closeChatDiv.style.display = "inline-block"
        this.closeChatDiv.style.position = "absolute"
        this.closeChatDiv.style.margin = "0"
        this.closeChatDiv.style.height = `${RESIZE_INDICATOR_WIDTH}px`
        this.closeChatDiv.style.width = `${RESIZE_INDICATOR_WIDTH}px`
        this.closeChatDiv.style.padding = `${ICON_PADDING}px`
        this.closeChatDiv.style.borderRadius = "4px"
        this.closeChatDiv.style.boxSizing = "border-box"
        this.closeChatDiv.style.textAlign = "center"
        this.closeChatDiv.style.top = `${CLOSE_CHAT_EXTRA_PADDING - RESIZE_INDICATOR_WIDTH / 2}px`
        this.closeChatDiv.style.left = `${CLOSE_CHAT_EXTRA_PADDING - RESIZE_INDICATOR_WIDTH / 2}px`
        this.closeChatDiv.style.background = "rgba(0, 0, 0, 0.3)"

        this.resizeIndicatorImg.src = `${STATIC_URL_MOBILE}3-bar.svg`
        this.resizeIndicatorImg.style.position = "absolute"
        this.resizeIndicatorImg.style.height = `${ICON_HEIGHT}px`
        this.resizeIndicatorImg.style.width = `${ICON_WIDTH}px`

        this.closeChatImg.src = `${STATIC_URL_MOBILE}close-button.svg`
        this.closeChatImg.style.height = "9px"
        this.closeChatImg.style.width = "9px"
        this.closeChatImg.style.padding = "0"
        this.closeChatImg.style.margin = "0"
        this.closeChatImg.classList.add("closeChatFullscreen")

        if (isPortrait()) {
            this.resizeIndicatorDiv.style.width = `${getViewportWidth() - RESIZE_INDICATOR_WIDTH - ICON_MARGIN * 4}px`
            this.resizeIndicatorDiv.style.height = `${RESIZE_INDICATOR_WIDTH}px`
            this.stylePortrait()
        } else {
            this.resizeIndicatorDiv.style.width = `${RESIZE_INDICATOR_WIDTH}px`
            const chatRect = this.resizeDragDiv.getBoundingClientRect()
            this.resizeIndicatorDiv.style.height = `${chatRect.height - RESIZE_INDICATOR_WIDTH}px`
            this.styleLandscape()
        }

        this.closeChatContainer.onclick = (event: Event) => {
            if (this.areEphemeralsVisible) {
                event.stopPropagation()
                if (isPortrait()) {
                    let transitionTime = Math.max(180, this.portraitHeight * 1.15)
                    transitionTime = Math.min(380, transitionTime)
                    this.heightDuration = transitionTime
                    this.updateTransitions()
                    this.setPortraitHeight(0, transitionTime)
                } else {
                    let transitionTime = Math.max(200, this.landscapeWidth * 1.5)
                    transitionTime = Math.min(450, transitionTime)
                    this.widthDuration = transitionTime
                    this.updateTransitions()
                    this.setLandscapeWidth(0, transitionTime)
                }
                this.showEphemerals()
                mobilePureChatDrag.fire(undefined)
            }
        }

        this.resizeIndicatorDiv.onclick = (event: Event) => {
            if (this.areEphemeralsVisible && this.isChatHidden()) {
                event.stopPropagation()
                this.unhideChat()
            }
        }
    }

    private unhideChat(): void {
        const transitionTime = 180
        if (isPortrait()) {
            this.heightDuration = transitionTime
            this.updateTransitions()
            this.setPortraitHeight(150, transitionTime)
        } else {
            this.widthDuration = transitionTime
            this.updateTransitions()
            this.setLandscapeWidth(150, transitionTime)
        }
    }

    private isChatHidden(): boolean {
        return (
            ( isPortrait() && this.portraitHeight < 5) ||
            (!isPortrait() && this.landscapeWidth < 5)
        )
    }

    private stylePortrait(): void {
        this.element.style.width = "100%"
        this.element.style.top = ""
        this.element.style.bottom = `${playerVideoControlsHeight + this.layoutConstraints.bottom() + this.inputFocusOffset}px`
        this.element.style.right = ""

        this.resizeDragDiv.style.height = `${RESIZE_WIDTH}px`
        this.resizeDragDiv.style.width = "100%"
        this.resizeDragDiv.style.left = "0"
        this.resizeDragDiv.style.top = `${-RESIZE_WIDTH * RESIZE_OFFSET}px`

        this.controlsContainerDiv.style.height = `${RESIZE_INDICATOR_WIDTH}px`
        this.controlsContainerDiv.style.width = "100%"
        this.controlsContainerDiv.style.top = "4px"
        this.controlsContainerDiv.style.left = "0"

        this.resizeIndicatorDiv.style.marginLeft = `${ICON_MARGIN}px`
        this.resizeIndicatorImg.style.left = `${(getViewportWidth() - ICON_WIDTH - ICON_MARGIN * 2) / 2}px`
        this.resizeIndicatorImg.style.bottom = `${ICON_PADDING}px`
        this.resizeIndicatorImg.style.transform = "rotate(90deg)"

        this.closeChatDiv.style.right = "0"
        this.closeChatDiv.style.marginRight = `${ICON_MARGIN}px`
        this.closeChatContainer.style.right = `${-CLOSE_CHAT_EXTRA_PADDING + ICON_MARGIN + RESIZE_INDICATOR_WIDTH / 2}px`
    }

    private styleLandscape(): void {
        this.element.style.height = ""
        this.element.style.top = `${LOGO_HEIGHT}px`
        this.element.style.bottom = `${playerVideoControlsHeight + this.layoutConstraints.bottom()}px`
        this.element.style.right = "10px"

        this.resizeDragDiv.style.width = `${RESIZE_WIDTH}px`
        this.resizeDragDiv.style.height = ""
        this.resizeDragDiv.style.left = `${-RESIZE_WIDTH * RESIZE_OFFSET}px`
        this.resizeDragDiv.style.top = "0"

        this.controlsContainerDiv.style.height = "100%"
        this.controlsContainerDiv.style.width = `${RESIZE_INDICATOR_WIDTH}px`
        this.controlsContainerDiv.style.top = "0"
        this.controlsContainerDiv.style.left = "4px"

        let resizeDragDivHeight: number
        if (window.innerHeight > 200) {
            // If the window is large enough, center the drag indicator based off the height of the entire pureChat.
            // This prevents the drag indicator from "jiggling" when the closeChat button hides when minimizing chat.
            resizeDragDivHeight = this.element.getBoundingClientRect().height
        } else {
            // When the window is small, center the drag indicator based off the height of its actual container.
            // Otherwise, the not-true centering becomes apparent
            resizeDragDivHeight = this.resizeIndicatorDiv.getBoundingClientRect().height
        }
        this.resizeIndicatorDiv.style.bottom = "0"
        this.resizeIndicatorDiv.style.marginLeft = "0"
        this.resizeIndicatorImg.style.left = `${RESIZE_INDICATOR_WIDTH / 2 - (ICON_WIDTH) / 2}px`
        this.resizeIndicatorImg.style.bottom = `${(resizeDragDivHeight - ICON_HEIGHT) / 2}px`
        this.resizeIndicatorImg.style.transform = "rotate(0deg)"

        this.closeChatDiv.style.right = ""
        this.closeChatDiv.style.marginRight = "0"
        this.closeChatContainer.style.right = `${-CLOSE_CHAT_EXTRA_PADDING + RESIZE_INDICATOR_WIDTH / 2}px`
    }

    private handleRemoveMessages(u: string): void {
        const removeList: HTMLElement[] = []
        for (const div of this.innerDiv.childNodes) {
            const casted = div as HTMLElement
            if (casted.getAttribute("data-nick") === u) {
                removeList.push(casted)
            }
        }
        for (const msg of removeList) {
            this.innerDiv.removeChild(msg)
        }
    }

    private opacityTimer: number
    private toggleEphemeralsVisibility(): void {
        if (this.areEphemeralsVisible) {
            this.hideEphemerals()
        } else {
            this.showEphemerals()
        }
    }

    // "ephemerals" refers to the components that quickly disappear when not interacting with the chat: resize handle and background
    private showEphemerals(): void {
        styleTransition(this.resizeDragDiv, `opacity ${FADE_DURATION}ms`)
        this.resizeDragDiv.style.opacity = "0.9"
        this.areEphemeralsVisible = true
        this.showBackground()
        clearTimeout(this.opacityTimer)
        this.opacityTimer = window.setTimeout(() => {
            this.hideEphemerals()
        }, 3500)
    }

    private hideEphemerals(delay = 0): void {
        styleTransition(this.resizeDragDiv, `opacity ${FADE_DURATION}ms ${delay}ms`)
        this.areEphemeralsVisible = false
        this.resizeDragDiv.style.opacity = "0"
        this.hideBackground(delay)
        clearTimeout(this.opacityTimer)
    }

    private updateTransitions(): void {
        const backgroundColor = `background-color ${FADE_DURATION}ms ${this.backgroundDelay}ms`
        const height = `height ${this.heightDuration}ms`
        const width = `width ${this.widthDuration}ms`
        styleTransition(this.element, [backgroundColor, height, width].join(","))
    }

    public isMirroringComponent(component: IMirrorableChatComponent): boolean {
        return this.chatComponentToMirror === component
    }

    public repositionChildren(): void {
        this.element.style.display = this.isVisible ? "block" : "none"
        if (isPortrait()) {
            this.setPortraitHeight(this.desiredPortraitHeight)
            this.stylePortrait()
        } else {
            this.setLandscapeWidth(this.desiredLandscapeWidth)
            this.styleLandscape()
        }
    }
}
