import { isNotLoggedIn } from "../../../common/auth"
import { DmsReport } from "../../../common/chatReport"
import { fetchUserMenuData } from "../../../common/fetchUserMenuData"
import { addPageAction } from "../../../common/newrelic"
import { UserNotesBadgeManager } from "../../../common/notes"
import { OverlayComponent } from "../../../common/overlayComponent"
import {
    getGenderIconUrl, getUserContextMenuSubText, getUserContextMenuText, getUsernameColorClass,
} from "../../../common/roomUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { isIgnored } from "../../api/ignore"
import { ReactComponentRegistry } from "../ReactRegistry"
import { handleDmInputFocus } from "./dmUtil"
import type { UsernameLabel } from "./usernameLabel"
import type { ChatReport } from "../../../common/chatReport"
import type { IMenuData } from "../../../common/fetchUserMenuData"
import type { IPrivateMessage, IUserInfo } from "../../../common/messageInterfaces"
import type { ReactComponent } from "../ReactRegistry"

type DmUserContextMenuProps = {
    currentUsername: string,
    userInfo: IUserInfo,
    usernameLabel?: UsernameLabel,
    tearDownFunc: () => void,
    popoutFunc: () => void,
    ignoreFunc: () => void,
    unignoreFunc: () => void,
    includePopoutLink: boolean,
    dontRepositionForThumbnail: boolean,
}

export function isShowingDMUserContextMenu(): boolean {
    return DmUserContextMenu.isVisible
}

export class DmUserContextMenu extends OverlayComponent {
    public static isVisible: boolean
    protected menuData: IMenuData
    private chatReport: ChatReport
    private menuRightSpan: HTMLSpanElement
    private ucmDescription: HTMLDivElement | undefined
    private menuContents: HTMLElement
    private reportLinkItem: HTMLLIElement
    private lastReceivedMessage?: IPrivateMessage
    private reactUserNote: ReactComponent | undefined

    constructor (private props: DmUserContextMenuProps) {
        super()
        this.element = this.createMenuContents()
        DmUserContextMenu.isVisible = true
        this.overlayClick.listen(() => {
            this.element.style.display = "none"
            this.tearDown()
        })
    }

    private tearDown = () => {
        this.props.tearDownFunc()
        this.reactUserNote?.dispose()
        DmUserContextMenu.isVisible = false
    }

    private popoutConversation = () => {
        this.props.popoutFunc()
        this.tearDown()
    }
    private ignoreUser = () => {
        this.props.ignoreFunc()
        this.tearDown()
    }
    private unignoreUser = () => {
        this.props.unignoreFunc()
        this.tearDown()
    }
    private reportUser = () => {
        if (this.lastReceivedMessage === undefined) {
            error("PMUserContextMenu - reporting with no received message")
            return
        }
        addPageAction("reportPMUser", { "location": "PMWindow" })
        this.chatReport = new DmsReport(this.props.userInfo.username, this.lastReceivedMessage, this.ignoreUser)
        this.element.removeChild(this.menuContents)
        this.element.appendChild(this.chatReport.element)
        this.chatReport.focusForm()
        this.chatReport.closeChatReportRequest.listen(() => {
            this.tearDown()
        })
    }

    private createMenuContents(): HTMLDivElement {
        const containerStyle: CSSX.Properties = {
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
            cursor: "initial",
            display: "none",
            position: "absolute",
            left: "0px",
            top: "0",
            minWidth: "188px",
            maxWidth: "220px",
            zIndex: 1001,
        }
        const menuRightStyle: CSSX.Properties = {
            display: "inline-block",
            cssFloat: "right",
            verticalAlign: "top",
            width: "36px",
            marginRight: "8px",
        }
        const ucmHeaderStyle: CSSX.Properties = {
            padding: "9px 0 4px 9px",
            position: "relative",
            overflow: "hidden",
            boxSizing: "border-box",
        }
        const leftPlaceholderStyle: CSSX.Properties = {
            width: "14px",
            height: "14px",
            cssFloat: "left",
        }
        const rightPlaceholderStyle: CSSX.Properties = {
            width: "14px",
            height: "14px",
            cssFloat: "right",
        }

        fetchUserMenuData(this.props.userInfo.username).then((data) => {
            this.menuRightSpan.replaceWith(<span style={menuRightStyle}>
                <UCMAgeIcon menuData={data} />
                <UCMGenderIcon gender={data.gender} />
            </span>)
            if (data.online) {
                this.element.insertBefore(<UCMThumbnail imgURL={data.imageUrl} />, this.menuContents)
                if (!this.props.dontRepositionForThumbnail) {
                    this.element.style.top = "-75px"
                }
            }
        }).catch((err) => {
            error("Could not process user menu request", {
                "username": this.props.userInfo.username,
                "reason": err.toString(),
            })
        })

        this.ucmDescription = <UCMDescription user={this.props.userInfo} />
        const ucmLinks = this.createUcmLinks()

        const userNoteDiv = document.createElement("div")
        const UserNoteClass = ReactComponentRegistry.get("UserNote")
        this.reactUserNote = new UserNoteClass(
            {
                username: this.props.userInfo.username,
                handleInputFocus: handleDmInputFocus,
                badgeManager: UserNotesBadgeManager.getOrCreateInstance(),
                isNotLoggedIn: isNotLoggedIn,
                removeMenu: this.tearDown,
            },
            userNoteDiv,
        )

        const usernameDiv = this.props.usernameLabel?.element ?? <UCMName userInfo={this.props.userInfo} />

        return <div style={containerStyle} colorClass="pmUcmContainer" onClick={e => e.stopPropagation()} data-testid="dm-ucm-container">
            <div ref={(el: HTMLDivElement) => this.menuContents = el}>
                <div style={ucmHeaderStyle} colorClass="pmUcmHeader">
                    {usernameDiv}
                    <span style={menuRightStyle} ref={(el: HTMLSpanElement) => this.menuRightSpan = el}>
                        <span colorClass="placeholder" style={leftPlaceholderStyle}/>
                        <span colorClass="placeholder" style={rightPlaceholderStyle}/>
                    </span>
                </div>
                { this.ucmDescription }
                { ucmLinks }
                { userNoteDiv }
            </div>
        </div>
    }

    private createUcmLinks(): HTMLUListElement {
        const style: CSSX.Properties = {
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            listStyle: "none",
            padding: "5px 0",
            margin: 0,
        }
        return <ul style={style} colorClass="pmUcmLinks">
            { this.props.includePopoutLink && <UCMLinkItem
                iconColorClass="pmUcmPopoutIcon"
                innerText={i18n.popoutInNewWindow}
                clickHandler={this.popoutConversation}
                newIconStyle={{ width: "12px", height: "12px", marginBottom: "1px" }}
                dataid="dm-popout"
            /> }
            <UCMLinkItem
                iconColorClass="pmUcmIgnoreIcon"
                innerText={isIgnored(this.props.userInfo.username) ? i18n.unignoreThisUserText : i18n.ignoreThisUserText}
                clickHandler={isIgnored(this.props.userInfo.username) ? this.unignoreUser : this.ignoreUser}
                dataid="ignore-toggle"
            />
            <UCMLinkItem
                ref={(el) => this.reportLinkItem = el as unknown as HTMLLIElement} // Type checker is not happy with ref on function based tsx elements
                iconColorClass="pmUcmReportIcon"
                innerText={i18n.reportThisUserText}
                clickHandler={this.reportUser}
                newLinkItemStyle={this.lastReceivedMessage !== undefined ? {} : { display: "none" }}
                dataid="report-room"
            />
        </ul>
    }

    public setLastReceivedMessage(pm?: IPrivateMessage): void {
        if (pm !== undefined) {
            this.lastReceivedMessage = pm
            this.reportLinkItem.style.display = "flex"
        }
    }

    public render(): HTMLDivElement {
        return this.element
    }
}

export const UCMThumbnail = (props: { imgURL: string }): void => {
    const userImgDivStyle: CSSX.Properties = {
        width: "100%",
        height: "75px",
        overflow: "hidden",
        borderRadius: "4px 4px 0px 0px",
    }
    const userImgStyle: CSSX.Properties = {
        position: "relative",
        width: "188px",
        top: "-50%",
        left: "50%",
        transform: "translateX(-50%)",
    }

    return (
        <div style={userImgDivStyle}>
            <img style={userImgStyle} src={props.imgURL} data-testid="thumbnail"/>
        </div>
    )
}

type UCMNameProps = {
    userInfo: IUserInfo,
}

const UCMName = (props: UCMNameProps): void => {
    const nameLinkStyle: CSSX.Properties = {
        fontWeight: "bold",
        textDecoration: "none",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "120px",
        display: "inline-block",
        cursor: "pointer",
    }
    const nameLink = <a href={`/${props.userInfo.username}/`} target="_blank"  rel="noopener noreferrer" data-testid="username" style={nameLinkStyle} onClick={(event: Event) => {
        addPageAction("ViewProfile", { "username": props.userInfo.username })
        event.stopPropagation()
    }}>
        {props.userInfo.username}
    </a>

    nameLink.onmouseenter = () => {
        nameLink.style.textDecoration = "underline"
    }

    nameLink.onmouseleave = () => {
        nameLink.style.textDecoration = "none"
    }
    return nameLink
}

type AgeProps = {
    menuData: {
        canAccess: boolean,
        displayAge: number,
    } | undefined,
    newAgeStyle?: CSSX.Properties,
}

export const UCMAgeIcon = (props: AgeProps): HTMLSpanElement => {
    const ageStyle: CSSX.Properties = {
        display: "inline-block",
        width: "15px",
        minHeight: "14px",
        fontSize: "12px",
        fontWeight: "bold",
        marginRight: "6px",
        verticalAlign: "top",
        ...props.newAgeStyle,
    }
    let ageNumber: number | string = ""
    if (props.menuData?.canAccess === true) {
        if (!isNaN(props.menuData.displayAge) && props.menuData.displayAge >= 18) {
            ageNumber = props.menuData.displayAge
        }
    }
    ageStyle.background = ""
    return <span style={ageStyle} colorClass="ageIcon" data-testid="age">{ageNumber}</span>
}

type GenderIconProps = {
    gender: string | undefined,
    newGenderSpanStyle?: CSSX.Properties,
    newGenderIconStyle?: CSSX.Properties,
}

export const UCMGenderIcon = (props: GenderIconProps): HTMLSpanElement => {
    const genderSpanStyle: CSSX.Properties = {
        display: "inline-block",
        width: "15px",
        height: "14px",
        verticalAlign: "top",
        ...props.newGenderSpanStyle,
    }
    const genderIconStyle: CSSX.Properties = {
        display: "inline-block",
        width: "14px",
        height: "14px",
        ...props.newGenderIconStyle,
    }

    let genderIcon = <img />
    if (props.gender !== undefined && props.gender !== "") {
        genderIcon = <img style={genderIconStyle} src={getGenderIconUrl(props.gender)}
                          title={i18n.genderSymbolToIconTitle(props.gender)} data-testid="gender-icon"/>
    }

    return <span style={genderSpanStyle}>{genderIcon}</span>
}

type UCMDescriptionProps = {
    user: IUserInfo,
}

export const UCMDescription = (props: UCMDescriptionProps): HTMLDivElement | undefined => {
    const titleText = getUserContextMenuText(props.user)
    const titleColorClass = getUsernameColorClass(props.user)
    const subTitleText = getUserContextMenuSubText(props.user)

    if (titleText !== undefined) {
        const UCMDescriptionStyle: CSSX.Properties = {
            padding: "9px 10px",
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            fontSize: "12px",
        }
        const titleStyle: CSSX.Properties = {
            height: "12px",
            width: "100%",
            fontWeight: "bold",
        }
        const subTitleStyle: CSSX.Properties = {
            height: "10px",
            fontSize: "10px",
            marginTop: "4px",
            WebkitTextSizeAdjust: "none",
        }
        let moreLabel = ""
        if (subTitleText !== undefined) {
            moreLabel = <div style={subTitleStyle}>{subTitleText}</div>
        }
        return <div style={UCMDescriptionStyle} colorClass="pmUcmDescription">
            <div style={titleStyle} colorClass={titleColorClass} data-testid="user-label">{titleText}</div>
            {moreLabel}
        </div>
    }
    return undefined
}

type UCMLinkItemProps = {
    innerText: string,
    iconColorClass: string,
    clickHandler?: () => void,
    newLinkItemStyle?: CSSX.Properties,
    newIconStyle?: CSSX.Properties,
    dataid?: string
}
export const UCMLinkItem = (props: UCMLinkItemProps): HTMLLIElement => {
    const linkItemStyle: CSSX.Properties = {
        display: "flex",
        padding: "3px 8px 3px 12px",
        whiteSpace: "nowrap",
        fontWeight: "normal",
        fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
        fontSize: "12px",
        WebkitTextSizeAdjust: "none",
        cursor: "pointer",
        boxSizing: "border-box",
        ...props.newLinkItemStyle,
    }
    const iconStyle: CSSX.Properties = {
        flexShrink: 0,
        padding: "0px 4px 0px 0px",
        width: "14px",
        height: "14px",
        display: "inline-block",
        marginRight: "4px",
        marginTop: "2px",
        ...props.newIconStyle,
    }
    const linkTextStyle: CSSX.Properties = {
        fontWeight: "normal",
        fontFamily: "Tahoma, Arial, Helvetica, sans-serif",
        fontSize: "12px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitTextSizeAdjust: "none",
    }
    const linkItem = <li style={linkItemStyle} onClick={props.clickHandler} colorClass="pmUcmLinkItem">
        <span style={iconStyle} colorClass={props.iconColorClass}/>
        <span style={linkTextStyle} data-testid={props.dataid}>{props.innerText}</span>
    </li>
    return linkItem
}
