import { isiOS } from "@multimediallc/web-utils/modernizr"
import {
    getTokenBalance, sendTip,
    tipMessageMargin, tipsInPast24HoursUpdate,
} from "../../cb/api/tipping"
import { addColorClass } from "../../cb/colorClasses"
import { pageContext } from "../../cb/interfaces/context"
import { cleanTipAmountInput, createTipAmountInput, isValidTipInput, popUpPurchasePage, popUpTokenPurchaseModal, recordTipTypeViewed } from "../../cb/ui/tipping"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { onTipSent, roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { EventRouter } from "../events"
import { addPageAction } from "../newrelic"
import { RoomStatus } from "../roomStatus"
import { RoomType } from "../roomUtil"
import { i18n } from "../translation"
import { SendTipButton } from "./sendTipButton"
import { getViewportWidth } from "./viewportDimension"
import { isPortrait } from "./windowOrientation"
import type { ITipRequest } from "../specialoutgoingmessages"

export class TipCallout extends Component {
    private overlay: HTMLDivElement
    private closeWindowDiv: HTMLDivElement
    private content: HTMLDivElement
    private tokenBalanceSpan: HTMLSpanElement
    private tokenBalance = 0
    private sendTipButton: SendTipButton
    private sendTipForm: HTMLFormElement
    private tipAmountInput: HTMLInputElement
    private invalidTipAmountSpan: HTMLSpanElement
    private tipMessageLabel: HTMLDivElement
    private tipMessageDiv: HTMLDivElement
    private tipMessageTextarea: HTMLTextAreaElement
    private tipOptionsSelect: HTMLSelectElement | undefined
    private lowScoreWarning: HTMLDivElement
    private isHighTipAmountWarningActive = false
    private hasLowSatisfactionScore: boolean
    private visibleViewportHeight = window.visualViewport?.height ?? window.innerHeight
    private roomName: string
    private roomType = RoomType.Public
    private isSamsungBrowser: boolean
    public tipSent = onTipSent
    public closed = new EventRouter<undefined>("tipClosed")

    constructor(private tipSource: string) {
        super()

        this.isSamsungBrowser = navigator.userAgent.indexOf("SamsungBrowser") > -1

        // region DOM Creation
        this.element.style.display = "none"
        this.element.style.overflow = "visible"
        this.element.style.zIndex = "1003"
        this.element.style.backgroundColor = "rgba(0, 0, 0, 0.7)"
        this.element.style.height = "100%"
        this.element.style.bottom = "0"
        this.element.style.alignItems = "center"
        this.element.style.justifyContent = "center"
        this.element.style.position = "fixed"
        this.element.dataset.testid = "send-tip-callout"

        this.overlay = document.createElement("div")
        this.overlay.style.position = "fixed"
        this.overlay.style.top = "0"
        this.overlay.style.left = "0"
        this.overlay.style.right = "0"
        this.overlay.style.bottom = "0"
        this.overlay.onclick = () => {
            this.tipSent.fire({})
        }
        this.element.appendChild(this.overlay)

        const wrapper = document.createElement("div")
        this.element.appendChild(wrapper)

        this.closeWindowDiv = document.createElement("div")
        this.closeWindowDiv.innerText = `${i18n.closeWindow} (${i18n.cancelLower})`
        this.closeWindowDiv.style.color = "#ffffff"
        this.closeWindowDiv.style.fontSize = "14px"
        this.closeWindowDiv.style.fontWeight = "bold"
        this.closeWindowDiv.style.textDecoration = "underline"
        this.closeWindowDiv.style.position = "relative"
        this.closeWindowDiv.style.paddingBottom = "6px"
        this.closeWindowDiv.style.cursor = "pointer"
        this.closeWindowDiv.style.width = "100%"
        this.closeWindowDiv.style.textAlign = "right"
        this.closeWindowDiv.style.cursor = "pointer"
        this.closeWindowDiv.dataset.testid = "close-tip"
        this.closeWindowDiv.onclick = () => {
            this.tipSent.fire({})
        }
        wrapper.appendChild(this.closeWindowDiv)

        this.content = document.createElement("div")
        this.content.style.position = "relative"
        this.content.style.overflow = "visible"
        this.content.style.backgroundColor = "#ffffff"
        this.content.style.height = "auto"
        this.content.style.borderRadius = "6px"
        this.content.style.border = "1px solid #000000"
        addEventListenerPoly("click", this.content, (event: MouseEvent) => {
            event.stopPropagation() // Prevent the click from bubbling up to the overlay.
        })

        const tokenBalanceDiv = document.createElement("div")
        const balanceLabel = document.createElement("span")
        balanceLabel.innerText = i18n.currentBalanceText
        balanceLabel.style.display = "inline-block"
        balanceLabel.style.fontSize = "13px"
        balanceLabel.style.padding = "6px"
        tokenBalanceDiv.appendChild(balanceLabel)
        this.tokenBalanceSpan = document.createElement("span")
        this.tokenBalanceSpan.style.display = "inline-block"
        this.tokenBalanceSpan.style.color = "#53843a"
        this.tokenBalanceSpan.style.fontSize = "13px"
        this.tokenBalanceSpan.style.padding = "6px 6px 6px 0"
        this.tokenBalanceSpan.dataset.testid = "token-balance"
        tokenBalanceDiv.appendChild(this.tokenBalanceSpan)
        const purchaseTokensLink = document.createElement("a")
        purchaseTokensLink.innerText = i18n.purchaseTokensText
        purchaseTokensLink.style.display = "inline-block"
        purchaseTokensLink.style.color = "#f47321"
        purchaseTokensLink.style.fontSize = "13px"
        purchaseTokensLink.style.marginLeft = "12px"
        purchaseTokensLink.style.padding = "6px"
        purchaseTokensLink.dataset.testid = "purchase-tokens"
        purchaseTokensLink.onclick = () => {
            const purchaseSource = pageContext.current.PurchaseEventSources["TOKEN_SOURCE_TIP_CALLOUT"]
            popUpPurchasePage({ source: purchaseSource, "roomType": this.roomType })
            this.tipSent.fire({})
        }

        tokenBalanceDiv.appendChild(purchaseTokensLink)
        this.content.appendChild(tokenBalanceDiv)

        this.lowScoreWarning = document.createElement("div")
        this.lowScoreWarning.dataset.testid = "low-score-warning"
        this.lowScoreWarning.style.display = "none"
        this.lowScoreWarning.style.color = "#ff0000"
        this.lowScoreWarning.style.fontSize = "13px"
        this.lowScoreWarning.style.fontWeight = "bold"
        this.lowScoreWarning.style.padding = "6px"
        this.lowScoreWarning.style.textAlign = "center"
        const lowScoreWarningTop = document.createElement("div")
        lowScoreWarningTop.innerText = i18n.satisfactionWarningText
        this.lowScoreWarning.appendChild(lowScoreWarningTop)
        const lowScoreWarningBottom = document.createElement("div")
        lowScoreWarningBottom.innerText = i18n.tipWarningText
        this.lowScoreWarning.appendChild(lowScoreWarningBottom)
        this.content.appendChild(this.lowScoreWarning)

        this.sendTipForm = document.createElement("form")
        this.sendTipForm.dataset.testid = "send-tip-form"

        const tipAmountDiv = document.createElement("div")
        applyStyles(tipAmountDiv, {
            "display": "flex",
            "alignItems": "center",
            "marginTop": "12px",
            "marginBottom": "6px",
        })
        const tipAmountLabel = document.createElement("span")
        tipAmountLabel.innerText = i18n.tipAmountText
        tipAmountLabel.style.display = "inline-block"
        tipAmountLabel.style.fontSize = "13px"
        tipAmountLabel.style.padding = "6px"
        tipAmountDiv.appendChild(tipAmountLabel)
        this.tipAmountInput = createTipAmountInput()
        this.tipAmountInput.dataset.testid = "tip-amount-input"
        addColorClass(this.tipAmountInput, "inputAmt")
        this.tipAmountInput.onclick = () => {
            this.tipAmountInput.setSelectionRange(0, this.tipAmountInput.value.length)
        }
        this.tipAmountInput.onmouseup = (event: MouseEvent) => {
            // This prevents the mouse up from resetting the selection on mobile browsers.
            event.preventDefault()
        }
        this.tipAmountInput.onfocus = () => {
            this.tipAmountInput.setSelectionRange(0, this.tipAmountInput.value.length)
        }

        this.tipAmountInput.onblur = () => {
            if (this.tipAmountInput.value === "") {
                this.tipAmountInput.value = "0"
            }
        }

        addEventListenerPoly("input", this.tipAmountInput, () => {
            this.tipAmountChange()
        })

        tipAmountDiv.appendChild(this.tipAmountInput)

        this.invalidTipAmountSpan = document.createElement("span")
        this.invalidTipAmountSpan.innerText = i18n.tipAmountInvalid
        this.invalidTipAmountSpan.style.fontSize = "12px"
        this.invalidTipAmountSpan.style.color = "#ff0000"
        this.invalidTipAmountSpan.style.display = "none"
        this.invalidTipAmountSpan.style.marginLeft = "5px"
        this.invalidTipAmountSpan.style.marginRight = "5px"
        this.invalidTipAmountSpan.dataset.testid = "invalid-tip"
        tipAmountDiv.appendChild(this.invalidTipAmountSpan)

        this.sendTipForm.appendChild(tipAmountDiv)

        this.tipMessageLabel = document.createElement("div")
        this.tipMessageLabel.innerText = i18n.defaultTipMessageLabel
        this.tipMessageLabel.style.fontSize = "13px"
        this.tipMessageLabel.style.padding = "6px 6px 0 6px"
        this.sendTipForm.appendChild(this.tipMessageLabel)

        this.tipMessageDiv = document.createElement("div")
        this.tipMessageTextarea = document.createElement("textarea")
        this.tipMessageTextarea.maxLength = 255
        this.tipMessageTextarea.style.width = "100%"
        this.tipMessageTextarea.style.resize = "none"
        this.tipMessageTextarea.style.fontSize = "1em"
        this.tipMessageTextarea.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        this.tipMessageTextarea.style.margin = `${tipMessageMargin}px`
        this.tipMessageTextarea.style.padding = "4px"
        this.tipMessageTextarea.style.border = "1px solid #4b4c4b"
        this.tipMessageTextarea.style.borderRadius = "4px"
        this.tipMessageTextarea.style.boxSizing = "border-box"
        this.tipMessageTextarea.classList.add("tipMessageTextarea")
        this.tipMessageTextarea.dataset.testid = "tip-message-textarea"

        this.tipMessageDiv.appendChild(this.tipMessageTextarea)
        this.sendTipForm.appendChild(this.tipMessageDiv)

        const sendTipButtonDiv = document.createElement("div")
        sendTipButtonDiv.style.textAlign = "right"
        sendTipButtonDiv.style.position = "relative"

        this.sendTipButton = new SendTipButton()
        this.sendTipButton.element.style.cssFloat = ""
        this.addChild(this.sendTipButton, sendTipButtonDiv)
        this.sendTipForm.appendChild(sendTipButtonDiv)

        this.content.appendChild(this.sendTipForm)
        wrapper.appendChild(this.content)
        // endregion

        this.closed.listen(() => {
            this.sendTipButton.hideMenu()
        })

        // check for keyboard open/close by listening to resize event of the visible viewport
        window.visualViewport?.addEventListener("resize", () => {
            this.visibleViewportHeight = window.visualViewport?.height ?? window.innerHeight
            this.repositionChildren()
        })

        roomLoaded.listen((context) => {
            this.hasLowSatisfactionScore = context.dossier.hasLowSatisfactionScore
            this.roomName = context.chatConnection.room()
            context.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                this.roomType = roomStatusChangeNotification.currentStatus === RoomStatus.PrivateWatching ? RoomType.Private : RoomType.Public
                purchaseTokensLink.onclick = () => {
                    const purchaseSource = pageContext.current.PurchaseEventSources["TOKEN_SOURCE_TIP_CALLOUT"]
                    popUpPurchasePage({ source: purchaseSource, "roomType": this.roomType })
                    this.tipSent.fire({})
                }
            })
        })

        this.repositionChildren()

        addEventListenerPoly("submit", this.sendTipForm, (event: Event) => {
            event.preventDefault()

            if (!isValidTipInput(this.tipAmountInput.value)) {
                modalAlert(i18n.tipAmountInvalid)
                return
            }

            if (!this.sendTipButton.isEnabled()) {
                return
            }

            const amount = parseInt(this.tipAmountInput.value)

            if (amount > this.tokenBalance) {
                popUpTokenPurchaseModal(i18n.notEnoughTokensMessage, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"], this.roomType)
                return
            }

            addPageAction("SendTipClicked", { "amount": amount })

            if (amount > 100 && !this.isHighTipAmountWarningActive) {
                this.sendTipButton.promptUser(i18n.tipConfirmationMessage(amount))
                this.isHighTipAmountWarningActive = true
                this.repositionChildren()
                return
            }
            let message = this.tipMessageTextarea.value
            if (this.tipOptionsSelect !== undefined) {
                message = this.tipMessageTextarea.value === ""
                    ? this.tipOptionsSelect.value
                    : `${this.tipOptionsSelect.value} | ${this.tipMessageTextarea.value}`
            }

            // store the selected tip type options in selectedTipType variable
            const selectedTipType = this.sendTipButton.getTipType()

            sendTip({
                roomName: this.roomName,
                tipAmount: this.tipAmountInput.value,
                message: message,
                source: this.tipSource,
                tipRoomType: this.roomType,
                tipType: selectedTipType,
                videoMode: "mobile",
            }).then((sendTipResponse) => {
                if (sendTipResponse.success) {
                    addPageAction("SendTipSuccess", { "amount": amount })
                } else  {
                    if (sendTipResponse.error !== undefined) {
                        popUpTokenPurchaseModal(`${sendTipResponse.error}`, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"], this.roomType)
                    } else {
                        error("unknown send tip error")
                    }
                }
                this.removeHighTipAmountWarning()
                this.tipMessageTextarea.value = ""
                if (sendTipResponse.tipsInPast24Hours !== undefined) {
                    tipsInPast24HoursUpdate.fire({ tokens: sendTipResponse.tipsInPast24Hours, roomName: this.roomName })
                }
                this.tipSent.fire({ amount, success: sendTipResponse.success })
            }).catch((err) => {
                this.tipSent.fire({ amount, success: false })
                error(`Error sending tip (${err})`)
            })
            this.hide()
        })

        addEventListenerPoly("popstate", window, (event: PopStateEvent) => {
            if(this.isShown()) {
                this.hide()
            }
        })
    }

    protected repositionChildren(): void {
        if (!isPortrait()) {
            this.hide()
        } else {
            // 1. Tip window is not fully visible on iOS when the keyboard is open unless height is 100%.
            // 2. On samsung internet mobile browser, when user touches the message area in tip window, it pushes the
            // tip window off screen - so setting the height to 100% fixes the issue
            if (isiOS() || this.isSamsungBrowser) {
                this.element.style.height = "100%"
            } else {
                // Also, for some android devices, even with the previous height fix, the keyboard overlaps
                // some portion of the send tip button. Positioning the modal content
                // with the help of visible viewport fixes the issue
                this.element.style.height = `${this.visibleViewportHeight}px`
                this.element.style.top = "0"
                this.element.style.bottom = ""
            }
            this.content.style.width = `${Math.min(getViewportWidth() * 0.9, 500)}px`
            this.tipMessageDiv.style.width = `${this.content.clientWidth - (tipMessageMargin * 2)}px`
        }
    }

    private removeHighTipAmountWarning(): void {
        this.sendTipButton.cancelPrompt()
        this.isHighTipAmountWarningActive = false
    }

    private tipAmountChange(): void {
        cleanTipAmountInput(this.tipAmountInput)
        if (!isValidTipInput(this.tipAmountInput.value)) {
            this.sendTipButton.disable()
            this.invalidTipAmountSpan.style.display = "inline-block"
        } else {
            this.sendTipButton.enable()
            this.invalidTipAmountSpan.style.display = "none"
        }
        if (this.isHighTipAmountWarningActive) {
            this.removeHighTipAmountWarning()
        }
    }

    public show(tipRequest: ITipRequest): void {
        if (tipRequest.amount !== undefined) {
            this.tipAmountInput.value = tipRequest.amount.toString()
            this.tipAmountChange()
        }
        if (tipRequest.message !== undefined) {
            this.tipMessageTextarea.value = tipRequest.message
        } else {
            this.tipMessageTextarea.value = ""
        }
        if (this.hasLowSatisfactionScore) {
            this.lowScoreWarning.style.display = "block"
        } else {
            this.lowScoreWarning.style.display = "none"
        }
        this.tokenBalanceSpan.innerText = `${i18n.loadingTextLower}...`
        this.showElement()
        this.tipAmountInput.focus()

        recordTipTypeViewed(this.tipSource, this.sendTipButton)
        getTokenBalance(this.roomName).then((currentTokensResponse) => {
            this.updateTokenBalance(currentTokensResponse.tokenBalance)
            this.tipAmountInput.max = currentTokensResponse.tokenBalance.toString()

            if (currentTokensResponse.tipOptions !== undefined) {
                this.tipMessageLabel.innerText = currentTokensResponse.tipOptions.label
                if (this.tipOptionsSelect !== undefined) {
                    this.tipMessageDiv.removeChild(this.tipOptionsSelect)
                }
                this.tipOptionsSelect = document.createElement("select")
                this.tipOptionsSelect.style.width = "100%"
                this.tipOptionsSelect.style.fontSize = "1em"
                this.tipOptionsSelect.style.margin = `${tipMessageMargin}px`
                this.tipOptionsSelect.style.border = "1px solid #4b4c4b"
                this.tipOptionsSelect.style.boxSizing = "border-box"
                this.tipMessageDiv.insertBefore(this.tipOptionsSelect, this.tipMessageTextarea)
                let option = document.createElement("option")
                option.innerText = `-- ${i18n.selectOneLabel} --`
                option.value = ""
                this.tipOptionsSelect.appendChild(option)
                for (const tipOption of currentTokensResponse.tipOptions.options) {
                    option = document.createElement("option")
                    option.innerText = tipOption.label
                    option.value = tipOption.label
                    this.tipOptionsSelect.appendChild(option)
                }
            } else if (this.tipOptionsSelect !== undefined) {
                this.tipMessageLabel.innerText = i18n.defaultTipMessageLabel
                this.tipMessageTextarea.value = ""
                this.tipMessageDiv.removeChild(this.tipOptionsSelect)
                this.tipOptionsSelect = undefined
            }
            this.repositionChildren()
        }).catch((err) => {
            error(`Error getting token balance (${err})`)
            this.tokenBalanceSpan.innerText = i18n.unknownText
        })
    }

    public showElement(): void {
        super.showElement("flex")
    }

    public hide(): void {
        this.element.style.display = "none"
        this.closed.fire(undefined)
    }

    private updateTokenBalance(tokenBalance: number): void {
        this.tokenBalance = tokenBalance
        this.tokenBalanceSpan.innerText = `${tokenBalance} ${i18n.tokenOrTokensText(tokenBalance, false)}`
    }
}
