import { ArgJSONMap } from "@multimediallc/web-utils"
import { tipsInPast24HoursUpdate, tokenBalanceUpdate } from "../cb/api/tipping"
import { addColorClass } from "../cb/colorClasses"
import { modalConfirm } from "./alerts"
import { getCb, postCb } from "./api"
import { stringPart } from "./chatconnection/roomnoticeparts"
import { roomLoaded } from "./context"
import { Component } from "./defui/component"
import { addPageAction } from "./newrelic"
import { OptimisticUpdate } from "./optimisticUpdate"
import { RoomStatus } from "./roomStatus"
import { i18n } from "./translation"
import type { IChatConnection, IRoomContext } from "./context";
import type { ITipInPast24HoursNotification } from "./messageInterfaces"

export const minTokensToVote = 25

export interface IVoteState {
    hasVoted: boolean | undefined,
    voteDirection: string | undefined
}

export abstract class SatisfactionScore extends Component<HTMLDivElement> {
    protected innerDiv = document.createElement("div")
    protected votesSection = document.createElement("div")
    private upContainer = document.createElement("div")
    private downContainer = document.createElement("div")
    private upVotes = document.createElement("span")
    private downVotes = document.createElement("span")
    protected thumbsUp = document.createElement("img")
    protected thumbsDown = document.createElement("img")
    protected thumbsUpActive = document.createElement("img")
    protected thumbsDownActive = document.createElement("img")
    protected percent = document.createElement("span")
    protected roomName = ""
    protected tipsInPast24Hours = 0
    protected voteInLast90DaysDown = false
    protected chatConn: IChatConnection
    protected hasVoted: boolean | undefined
    protected voteDirection: string | undefined
    protected voteUpdate = new OptimisticUpdate<IVoteState>(
        {
            hasVoted: false,
            voteDirection: undefined,
        },
        (voteState: IVoteState, isPending: boolean) => this.setVoteState(voteState, isPending),
        () => this.onVoteError(),
    )
    private listeningForTipUpdates = false

    constructor() {
        super()

        this.votesSection.style.display = "inline-block"
        this.votesSection.style.verticalAlign = "top"

        this.upContainer.style.display = "inline-block"
        this.upContainer.style.cursor = "pointer"
        this.upContainer.style.verticalAlign = "top"

        this.upVotes.style.margin = "0 3px"
        this.upVotes.style.verticalAlign = "top"
        this.upContainer.appendChild(this.upVotes)
        this.createThumbsUp()
        this.upContainer.appendChild(this.thumbsUp)
        this.upContainer.appendChild(this.thumbsUpActive)
        this.votesSection.appendChild(this.upContainer)

        this.percent.style.margin = "0 3px"
        this.percent.style.verticalAlign = "top"
        this.percent.onclick = () => addPageAction("SatisfiedClicked")
        this.votesSection.appendChild(this.percent)

        this.downContainer.style.display = "inline-block"
        this.downContainer.style.cursor = "pointer"
        this.downContainer.style.verticalAlign = "top"

        this.createThumbsDown()
        this.downContainer.appendChild(this.thumbsDown)
        this.downContainer.appendChild(this.thumbsDownActive)
        this.downVotes.style.margin = "0 3px"
        this.downVotes.style.verticalAlign = "top"
        this.downContainer.appendChild(this.downVotes)
        this.votesSection.appendChild(this.downContainer)

        this.innerDiv.appendChild(this.votesSection)
        this.element.appendChild(this.innerDiv)

        this.voteUpdate.setInitialState({
            hasVoted: false,
            voteDirection: undefined,
        })

        roomLoaded.listen((context: IRoomContext) => {
            this.chatConn = context.chatConnection
            this.roomName = context.dossier.room
            this.tipsInPast24Hours = context.dossier.tipsInPast24Hours

            if (context.dossier.hideSatisfactionScore && context.dossier.staffInfo === undefined) {
                this.upVotes.style.display = "none"
                this.percent.style.display = "none"
                this.downVotes.style.display = "none"
            } else {
                this.upVotes.innerText = `${context.dossier.satisfactionScore.upVotes}`
                this.percent.innerText = `${context.dossier.satisfactionScore.percent}%`
                this.downVotes.innerText = `${context.dossier.satisfactionScore.downVotes}`
                this.upVotes.style.display = "inline"
                this.percent.style.display = "inline"
                this.downVotes.style.display = "inline"
                this.percent.dataset.testid = "satisfaction-score-percentage"
                this.percent.className = "" // Clear classes so only one is applied at a time
                if (context.dossier.satisfactionScore.percent > 85) {
                    addColorClass(this.percent, "highPercent")
                } else if (context.dossier.satisfactionScore.percent > 65) {
                    addColorClass(this.percent, "mediumPercent")
                } else {
                    addColorClass(this.percent, "lowPercent")
                }
            }

            if (context.dossier.lastVoteInPast24Hours === 0) {
                this.voteUpdate.dispatch({
                    hasVoted: true,
                    voteDirection: "down",
                }, Promise.resolve(true))
            } else if (context.dossier.lastVoteInPast24Hours === 10) {
                this.voteUpdate.dispatch({
                    hasVoted: true,
                    voteDirection: "up",
                }, Promise.resolve(true))
            } else {
                this.voteUpdate.dispatch({
                    hasVoted: false,
                    voteDirection: undefined,
                }, Promise.resolve(true))
            }
            this.voteInLast90DaysDown = context.dossier.lastVoteInPast90DaysDown

            if (this.tipsInPast24Hours >= minTokensToVote) {
                this.enableVoting()
            } else {
                this.disableVoting()
            }

            if (!this.listeningForTipUpdates) {
                this.listenForTipUpdates()
            }
        })
    }

    protected onVoteError(): void {
    }

    protected enableVoteMsg(): string {
        return i18n.afterTipVoteSatisfactionText(this.roomName, minTokensToVote)
    }

    protected styleThumb(thumb: HTMLImageElement, source: string): void {
        thumb.src = source
        thumb.height = 15
        thumb.width = 15
        thumb.style.height = "15px"
        thumb.style.width = "15px"
        thumb.style.verticalAlign = "top"
        thumb.style.margin = "0 3px"
        thumb.style.opacity = "0.8"
    }

    protected createThumbsUp(): void {
        this.styleThumb(this.thumbsUp, `${STATIC_URL}thumbs-up-inactive.svg`)
        this.styleThumb(this.thumbsUpActive, `${STATIC_URL}thumbs-up-active.svg`)
        this.upContainer.onclick = (ev: MouseEvent) => {
            addPageAction("SatisfiedClicked")
            this.stopProp(ev)
            if (this.tipsInPast24Hours >= minTokensToVote && (this.hasVoted === false || this.voteDirection === "down")) {
                this.triggerComment(true)
                const callUpVote = postCb(`tipping/rate_model/${this.roomName}/`, { "rating": "10" }).then(() => {
                    this.chatConn.event.roomNotice.fire({
                        messages: [[stringPart(i18n.votedUpText(this.roomName))]],
                        showInPrivateMessage: true,
                    })
                    return true
                }).catch((err) => {
                    error("Error rating model", err)
                    return false
                })
                this.voteUpdate.dispatch({
                    hasVoted: true,
                    voteDirection: "up",
                }, callUpVote)
            } else if (this.voteDirection === "up") {
                this.triggerComment(false)
            }
        }
    }

    protected createThumbsDown(): void {
        this.styleThumb(this.thumbsDown, `${STATIC_URL}thumbs-down-inactive.svg`)
        this.styleThumb(this.thumbsDownActive, `${STATIC_URL}thumbs-down-active.svg`)
        this.downContainer.onclick = (ev: MouseEvent) => {
            addPageAction("SatisfiedClicked")
            this.stopProp(ev)
            if (this.tipsInPast24Hours >= minTokensToVote && (this.hasVoted === false || this.voteDirection === "up")) {
                modalConfirm(i18n.sureVoteDownText(this.roomName), () => {
                    this.triggerComment(true)

                    const callDownVote = postCb(`tipping/rate_model/${this.roomName}/`, { "rating": "0" }).then(() => {
                        let message = i18n.votedDownText(this.roomName)
                        if (this.voteInLast90DaysDown) {
                            message = i18n.votedDownTextRepeat(this.roomName)
                        }
                        this.chatConn.event.roomNotice.fire({
                            messages: [[stringPart(message)]],
                            showInPrivateMessage: true,
                        })
                        return true
                    }).catch((err) => {
                        error("Error rating model", err)
                        return false
                    })

                    this.voteUpdate.dispatch({
                        hasVoted: true,
                        voteDirection: "down",
                    }, callDownVote)
                })
            } else if (this.voteDirection === "down") {
                this.triggerComment(false)
            }
        }
    }

    protected abstract triggerComment(reset: boolean): void

    protected stopProp(ev: MouseEvent): void {
        ev.stopPropagation()
    }

    protected enableVoting(): void {
        this.thumbsUp.style.opacity = "1"
        this.thumbsDown.style.opacity = "1"
        this.thumbsUp.style.cursor = "pointer"
        this.thumbsDown.style.cursor = "pointer"
    }

    protected disableVoting(): void {
        this.thumbsUp.style.opacity = "0.65"
        this.thumbsDown.style.opacity = "0.65"
        this.thumbsUp.style.cursor = ""
        this.thumbsDown.style.cursor = ""
    }

    protected setUpvoted(): void {
        this.hasVoted = true
        this.voteDirection = "up"
        this.thumbsDownActive.style.display = "none"
        this.thumbsDown.style.display = ""
        this.thumbsUpActive.style.display = ""
        this.thumbsUp.style.display = "none"
    }

    protected setDownvoted(): void {
        this.hasVoted = true
        this.voteDirection = "down"
        this.thumbsDownActive.style.display = ""
        this.thumbsDown.style.display = "none"
        this.thumbsUpActive.style.display = "none"
        this.thumbsUp.style.display = ""
    }

    protected setNotvoted(): void {
        this.hasVoted = false
        this.voteDirection = undefined
        this.thumbsDownActive.style.display = "none"
        this.thumbsDown.style.display = ""
        this.thumbsUpActive.style.display = "none"
        this.thumbsUp.style.display = ""
    }

    protected setVoteState(voteState: IVoteState, isPending: boolean): void {
        if (voteState.voteDirection === undefined || voteState.hasVoted === false) {
            this.setNotvoted()
        } else if (voteState.voteDirection === "up") {
            this.setUpvoted()
        } else if (voteState.voteDirection === "down") {
            this.setDownvoted()
        }
    }

    private listenForTipUpdates(): void {
        this.listeningForTipUpdates = true
        tipsInPast24HoursUpdate.listen((tipsInPast24Hours: ITipInPast24HoursNotification) => {
            if (this.chatConn.room() !== tipsInPast24Hours.roomName) {
                return
            }
            if (tipsInPast24Hours.tokens >= minTokensToVote) {
                this.enableVoting()
                if (this.tipsInPast24Hours < minTokensToVote) {
                    this.chatConn.event.roomNotice.fire({
                        messages: [[stringPart(this.enableVoteMsg())]],
                        showInPrivateMessage: true,
                    })
                }
            }
            this.tipsInPast24Hours = tipsInPast24Hours.tokens
        })

        // To enable voting during private show's automatic token transferring
        tokenBalanceUpdate.listen((ev) => {
            if ([RoomStatus.PrivateWatching, RoomStatus.PrivateSpying].indexOf(this.chatConn.status) !== -1) {
                getCb(`tipping/tips_in_last_24/${this.roomName}/`).then((xhr) => {
                    const tipsInPast24Hours = new ArgJSONMap(xhr.responseText).getNumber("tipped_performer_last_24hrs")
                    tipsInPast24HoursUpdate.fire({ tokens: tipsInPast24Hours, roomName: this.chatConn.room() })
                }).catch(() => {})
            }
        })
    }
}
